import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import "./Spinner.css";
import { useNavigate } from "react-router";

const UpdateAdminLink = ({ isOpen, onRequestClose, companyDetails }) => {
  const [adminLink, setAdminLink] = useState({
    adminlink: "",
    companycode:companyDetails.companycode
  });

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]); // State for storing accounts
  const [selectedEmail, setSelectedEmail] = useState(""); // State for selected email

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
        const response = await axios.get(`/api/accounts/role=ROLE_ADMIN`, { 
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAccounts(response.data); // Assuming response.data contains an array of accounts
      } catch (error) {
        handleError(error);
      }
    };

    fetchAccounts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

     // Include selected email in form data

    try {
      const response = await axios.post('/api/companydetails/update', 
        {
        id:companyDetails.id,
        adminlink:selectedEmail
        }
        , { // Replace with your actual endpoint
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      toast.success("Admin Link updated successfully");
      onRequestClose();
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Admin Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="emailSelect" className="me-1">Select Email:</label>
                    <select
                      id="emailSelect"
                      className="form-control"
                      value={selectedEmail}
                      onChange={(e) => setSelectedEmail(e.target.value)}
                    >
                      <option value="">Select an email</option>
                      {accounts.map((account) => (
                        <option key={account.id} value={account.id}> {/* Replace account.id with the appropriate unique identifier */}
                          {account.username}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
        
                <br />
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading || !selectedEmail} 
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default UpdateAdminLink;
