import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import { Pagination, Card, Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaRegUserCircle, FaTrash } from "react-icons/fa";
import AddEmployees from "./addEmployees";
import UploadEmployeesCsvFile from "./uploadEmployeesCsvFile";
import EmployeesCsvFormat from "./employeeCsvFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AdminDashboard from "./admindashboard";
import ArchiveEmployeeModal from "./archiveEmployeePopup";

function EmpDetails() {
  const [employeesDetails, setEmployeeDetails] = useState([]);
  const [loading, setLoading] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCsvUplaodModalOpen, setIsCsvUplaodModalOpen] = useState(false);
const [currentPage, setCurrentPage] = useState(0);
const [totalPages, setTotalPages] = useState(0);
const [searchTerm, setSearchTerm] = useState("");
const [showConfirmationModal, setShowConfirmationModal] = useState(false);

const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
  const navigate = useNavigate();
  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);

 
  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
   

    try {
      const searchUrl =`/api/employeedetails/getByCompanycode/${orgCode}`;
      const response = await axios.get(searchUrl,  {
        headers: { Authorization: `Bearer ${token}` },
      });

      setEmployeeDetails(response.data);
      setLoader(false);

    } catch (error) {
      handleError(error);
    }
  };
  const getData = async (pageNumber = 0) => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
  
      const trans = await axios.get(`/api/employeedetails/companycode=${orgCode}`, {
        params: { page: pageNumber }, 
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log(trans);
  
      setEmployeeDetails(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };
  function onClickhandler(e, id, disabled) {
    e.preventDefault();
    setLoading(true)
    const checked = e.target.checked;
   console.log(checked);
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    console.log(token);
    axios.post(`/api/employeedetails/update`, {
      id: id,
      disabled: disabled
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false);
        toast.success("Status updated successfully");
        getData();
      })
      .catch((error)=>{
        setLoading(false);

        handleError(error);
      })
  }

  function onClickhandlerAnnualCheckup(e, id, annualCheckup) {
    e.preventDefault();
    setLoading(true)
    const checked = e.target.checked;
   console.log(checked);
    const token = JSON.parse(localStorage.getItem("data")).token;
   
    console.log(token);
    axios.post(`/api/employeedetails/update`, {
      id: id,
      annualCheckup: annualCheckup
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false);
        toast.success("Annual Checkup Status updated successfully");
        getData();
      })
      .catch((error)=>{
        setLoading(false);

        handleError(error);
      })
  }
  const handleAddEmployee = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();

  };

  const handleUploadEmployeeCsv = () => {
    setIsCsvUplaodModalOpen(true);
  };

  const handleCloseUploadCsvModal = () => {
    setIsCsvUplaodModalOpen(false);
    getData();

  };
  const filteredData = employeesDetails.filter((agent) =>
    (agent.email && agent.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (agent.name && agent.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  

  const openUserProfileModal = async (userid) => {
    navigate(`/users/employee-profile/${userid}`);
  };

  const bulkUpdate = async (companycode) => {
    navigate(`/users/bulk-update/${companycode}`);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };


  const handleDeleteAccount = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoading(id);
    const vehicles = await axios({
      url: "/api/employeedetails",
      method: "delete",
 
      headers: { Authorization: `Bearer ${token}` },
 
      data: {
        id: `${id}`,
       
      },
    }).then((res) => {
      toast.success("Deleted successfully");
      getData();
    });
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = () => setShowImageModal(true);
  const handleCloseImageModal = () => setShowImageModal(false);

  const [employeeDetailsID, setEmployeeDetsilsId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleDelete = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    
    try {
      setLoading(true);
      
      const response = await axios.get(`/api/employeedetails/checkForDeletion/id=${id}`, options);
      
      if (response.data === false) {
        setShowModal(true);
        setEmployeeDetsilsId(id);
        setLoading(false);
      } else {
        setEmployeeDetsilsId(id);
        setShowConfirmationModal(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleDeleteConfirm = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    
    try {
      setLoading(true);
        const requestBody = {
          id: employeeDetailsID,
        };
  
        await axios.delete(`/api/employeedetails`, { data: requestBody, ...options });
        
        toast.success('Employee deleted successfully!');
        setLoading(false);
        setShowConfirmationModal(false);
        setEmployeeDetsilsId(null);
        getData();

    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };


  const handleCloseMessage = () => {
    setShowModal(false);
    setEmployeeDetsilsId(null);
    getData();
  }
  
 
  return (
   <div>
<div>
  <AdminDashboard />
</div>
{loading && <div className="loading"></div>}
  <div className="col-13  mx-xl-auto ps-xl-10">
    <div className="card card-raised " style={{marginTop:"10%"}}>
      <div className="card-body " >
      <div className="card-title form-group d-flex align-items-center justify-content-between">
        <span>Employees Details</span>

<div>
  <button className="btn btn-primary me-3" onClick={handleShowImageModal}>
                View Format
              </button>
    <button className="btn btn-primary me-3" onClick={handleUploadEmployeeCsv}>
      UPLOAD CSV
    </button>
    <button className="btn btn-primary" onClick={handleAddEmployee}>
      ADD
    </button>
  </div>
           </div>

           <div className="row align-items-center mt-3">
  <div className="col-auto">
    <button className="btn btn-primary"  onClick={() => bulkUpdate(orgCode)}>
      Bulk Update
    </button>
  </div>
  <div className="col d-flex justify-content-center">
    <div style={{ position: "relative", width: "250px" }}>
      <input
        type="text"
        className="form-control ps-14"
        placeholder="Search by Name or Email"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ paddingLeft: "30px" }}
      />
      <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
        <FontAwesomeIcon icon={faSearch} />
      </span>
    </div>
  </div>
  <div className="col-auto"></div> {/* Empty column to balance the layout */}
</div>


            <br/>
            <Row>
            {filteredData.map((accd) => (
              
            <Col key={accd.id} md={4} className="mb-3">
 <div
    className="card card-raised h-100"
    onClick={() => openUserProfileModal(accd.userid)}
    style={{ cursor: "pointer" }}
    title="View Profile"
  >
    <div className="card-body">
      <Card>
        <Card.Body>
        <Card.Text className="d-flex justify-content-between align-items-center">
            {/* Activity Status */}
            <div className="d-flex align-items-center" onClick={(e) => e.stopPropagation()}>
              <label className="form-check-label me-2" htmlFor="flexSwitchCheckDefault">
                Activity
              </label>
              <input
                className="form-check-input"
                id="flexSwitchCheckDefault"
                type="checkbox"
                defaultChecked={accd.disabled === false}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClickhandler(e, accd.id, !accd.disabled);
                }}
                title="Update Status"
                value={accd.id}
              />
            </div>

{/* Delete Icon */}
<div className="ms-3" onClick={(e) => { 
              e.stopPropagation(); 
              handleDelete(accd.id);
            }}>
            <FaTrash 
              style={{ cursor: "pointer" }} 
              title="Delete"
            />
          </div>
          </Card.Text>
          <Card.Text className="d-flex justify-content-between align-items-center">
            {/* Annual Checkup Toggle */}
            <div className="form-check form-switch" onClick={(e) => e.stopPropagation()}>
              <label className="form-check-label me-2" htmlFor="flexSwitchCheckAnnualCheckup">
                Annual Checkup
              </label>
              <input
                className="form-check-input"
                id="flexSwitchCheckAnnualCheckup"
                type="checkbox"
                checked={accd.annualCheckup === false}
                style={{ cursor: "pointer" }}
                onChange={(e) => {
                  e.stopPropagation();
                  onClickhandlerAnnualCheckup(e, accd.id, !accd.annualCheckup);
                }}
                title={accd.annualCheckup === false ? "Remove access" : "Give access"}
                value={accd.id}
              />
            </div>
             
          </Card.Text>

                {/* <Card.Text className="d-flex justify-content-end">
       
                    <MdDeleteForever
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteAccount(accd.id);
                      }}
                      title="Delete Account"
                    />
                                           </Card.Text> */}
                {/* <Card.Text>Id: {accd.id}</Card.Text> */}
             
                <div className="card-title text-center">
        {accd.image && accd.image ? (
          <img 
            src={`${accd.imageMeta},${accd.image}`} 
            alt="User Profile" 
            style={{ width: '100px', height: '100px', borderRadius: '50%' }} 
          />
        ) : (
          <FaRegUserCircle />
        )}
      </div>
                   <div className="card-title text-center">
 
                <Card.Text>{accd.name} {accd.lastname}</Card.Text>
                </div>
                  <br/>
                  <Card.Text>Email: {accd.email}</Card.Text>
                  <Card.Text>Phone Number: {accd.phoneno}</Card.Text>
                  <Card.Text>Designation: {accd.designation}</Card.Text>
                  <Card.Text>Date of Joining: {accd.dateOfJoining}</Card.Text>
                  <Card.Text className="card-footer bg-light">Employee Id: {accd.empid}</Card.Text>
               
                </Card.Body>
              </Card>
              </div>
              </div>
             
              </Col>
             
          ))}
                </Row>
                <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                  <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                  {renderPageNumbers()}
                  <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                  <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
                </Pagination>
              </div>
            </div>
          </div>
        {isModalOpen && (
          <AddEmployees isOpen={isModalOpen} onRequestClose={handleCloseModal} companycode={orgCode}/>
        )}
         {isCsvUplaodModalOpen && (
              <UploadEmployeesCsvFile isOpen={isCsvUplaodModalOpen} onRequestClose={handleCloseUploadCsvModal} companycode={orgCode} />
                )}

{showImageModal && (
           <EmployeesCsvFormat isOpen={showImageModal} onRequestClose={handleCloseImageModal} />
           )}

{showModal && (
<ArchiveEmployeeModal 
        isOpen={showModal}
        onRequestClose={handleCloseMessage}
        employeeId={employeeDetailsID} 
      />
)}
        </div>

        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      Are you sure you want to delete employee?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="me-3" onClick={() => setShowConfirmationModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDeleteConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
    );
}

export default EmpDetails;
