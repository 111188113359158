import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';  
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import ViewAppointmentsByLabCorporate from "./viewAppointmentsByLabCorporate";
import InvoicesBYLab from "./invoicesByLab";
import InvoicesByLab from "./invoicesByLab";
import ViewLabInvoices from "./viewLabInvoices";
import LabCoordinators from "./labCoordinators";
import LabInvoices from "./labInvoicesTab";

function LabsAndAppointmentsDetails() {
  const { labcode } = useParams();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('labprofile');
  const [orgProfile, setOrgProfile] = useState({});

  useEffect(() => {
    setLoading(true);
    
    const fetchProfile = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/labdetails/labcode=${labcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const userProfileDetails = response.data;
       setOrgProfile(userProfileDetails);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };

    fetchProfile();

  }, [labcode]);

  const handleBack = () => {
   navigate(-1);
  };

  const navigate = useNavigate(); 
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setOrgProfile({ ...orgProfile, [field]: actualValue }); 
  };

  function getBase64FromFile(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
         const [metadata, content] = reader.result.split(",");
         resolve({ metadata, content });};
         reader.onerror = function (error) {
         reject(error);};}); }
  
   const handleImageChange=async(event)=>{
     const convertedImage= await getBase64FromFile(event.target.files[0]);
     const imageObject = {...orgProfile,
           imageMeta: convertedImage.metadata,
           image:convertedImage.content,};
           setOrgProfile(imageObject);
   }

    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        await axios.post(`/api/labdetails/update`, orgProfile, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Lab Profile Updated Successfully");
        setLoading(false);
      } catch (error) {
        setLoading(false);
       handleError(error);   
      }
    };

  return (
    <div>
      <AdminDashboard />
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="lab-profile"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
           <Tab eventKey="labprofile" title="Lab Profile">
              {activeTab === 'labprofile' && (
                <>
                  {loading && <div className="loading"></div>}
                  <div className="col-12  mx-xl-auto">
                  <div className="mb-2">
                      <button className="btn btn-primary" onClick={handleBack}>
                        Back
                      </button>
                    </div>
          <div className="card card-raised">
            <div className="card-body " >
              <div className="card-title">Lab Profile</div>
              <div className="d-flex">
              <div className="me-4">
              {orgProfile.image ? (
                  <img
                    className="img-fluid circular-image mb-1"
                    id="image"
                    src={`${orgProfile?.imageMeta},${orgProfile?.image}`}
                    alt="User Profile"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",
                    }}
                  />
                ) : (
                  <div
                    className="img-fluid circular-image mb-1"
                    id="image"
                    style={{
                      width: "150px",
                      height: "150px",
                      display: "block",
                      borderRadius: "50%", // Use 50% border radius for a circular shape
                      background: "#ccc", // Set a background color for the icon
                      // textAlign: "center",
                      lineHeight: "170px",
                      fontSize: "24px",
                      // marginright: "60%",
                      marginTop:"60px",

                    }}
                  >
                    {/* <FaRegUserCircle size={250} /> */}
                  </div>
                )}
                <input
                  className="col-7"
                  type="file"
                  id="image"
                  // style={{ marginLeft: "10%" }}
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    handleImageChange(event);
                  }}
                  accept="image/*"
                />
              </div>
              <div className="flex-grow-1">
                <form onSubmit={handleSubmit} className="w-100">

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organizationname">Lab Name:</label>
                      <input
                        className="form-control"
                        id="labname"
                        type="text"
                        value={orgProfile.labname}
                        onChange={(e) => handleChange(e, "labname")}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Lab Code:</label>
                      <input
                        className="form-control"
                        id="labcode"
                        type="text"
                        value={orgProfile.labcode}
                        readOnly
                        // onChange={(e) => handleChange(e, "labcode")}
                      />
                    </div>
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Email ID:</label>
                      <input
                        className="form-control"
                        id="username"
                        type="email"
                        value={orgProfile.username}
                        readOnly
                        // onChange={(e) => handleChange(e, "username")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="organization">Contact Number <span style={{color:"red"}}>* </span>:</label>
                      <input
                        className="form-control"
                        id="contact"
                        type="text"
                        value={orgProfile.contact}
                        onChange={(e) => handleChange(e, "contact")}
                        required
                      />
                    </div>
                  </div>
                  </div>

                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">GSTIN:</label>
                      <input
                        className="form-control"
                        id="gstin"
                        type="text"
                        value={orgProfile.gstin}
                        onChange={(e) => handleChange(e, "gstin")}
                        readOnly
                      />
                    </div>
                  </div>
                 
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">PAN:</label>
                      <input
                        className="form-control"
                        id="pan"
                        type="text"
                        value={orgProfile.pan}
                        onChange={(e) => handleChange(e, "pan")}
                        readOnly
                      />
                    </div>
                  </div>

                <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">TAN:</label>
                      <input
                        className="form-control"
                        id="tan"
                        type="text"
                        value={orgProfile.tan}
                        onChange={(e) => handleChange(e, "tan")}
                        readOnly
                      />
                    </div>
                  </div>
                 </div>

                 <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine1">Address Line 1 <span style={{color:"red"}}>* </span>:</label>
                      <input
                        className="form-control"
                        id="addressLine1"
                        type="text"
                        value={orgProfile.addressLine1}
                        onChange={(e) => handleChange(e, "addressLine1")}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressLine2">Address Line 2:</label>
                      <input
                        className="form-control"
                        id="addressLine2"
                        type="text"
                        value={orgProfile.addressLine2}
                        onChange={(e) => handleChange(e, "addressLine2")}
                      />
                    </div>
                  </div>
                  </div>

                  <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="latitude">Latitude</label>
                    <input
                      className="form-control"
                      id="latitude"
                      type="text"
                      placeholder="Latitude"
                      value={orgProfile.latitude}
                      onChange={(e) => handleChange(e, "latitude")}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="longitude">Longitude</label>
                    <input
                      className="form-control"
                      id="longitude"
                      type="text"
                      placeholder="Longitude"
                      value={orgProfile.longitude}
                      onChange={(e) => handleChange(e, "longitude")}
                    />
                  </div>
                </div>
                </div>

                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">City <span style={{color:"red"}}>* </span>:</label>
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        value={orgProfile.city}
                        onChange={(e) => handleChange(e, "city")}
                        required
                      />
                    </div>
                  </div>

               
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pincode">Pincode <span style={{color:"red"}}>* </span>:</label>
                      <input
                        className="form-control"
                        id="pincode"
                        type="text"
                        value={orgProfile.pincode}
                        onChange={(e) => handleChange(e, "pincode")}
                        required
                      />
                    </div>
                  </div>
                
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">State <span style={{color:"red"}}>* </span>:</label>
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        value={orgProfile.state}
                        onChange={(e) => handleChange(e, "state")}
                        required
                      />
                    </div>
                  </div>
                  </div>

                  <div className="row">
  <div className="col-md-4">
    <div className="form-floating-sm mb-2">
      <label></label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          id="regulatedToggle"
          checked={orgProfile.regulated}
          onChange={(e) => handleChange({ target: { value: e.target.checked } }, "regulated")}
          style={{ cursor: "pointer" }} // Add pointer cursor
          title={orgProfile.regulated ? "Change to Unregulated" : "Change to Regulated"} // Add dynamic title
        />
        <label
          className="form-check-label ms-2"
          htmlFor="regulatedToggle"
          style={{ cursor: "pointer" }} // Add pointer cursor for the label
          title={orgProfile.regulated ? "Change to Unregulated" : "Change to Regulated"} // Add dynamic title for the label
        >
          {orgProfile.regulated ? "Regulated" : "Unregulated"}
        </label>
      </div>
    </div>
  </div>
</div>

                <br />
                <input
                  type="submit"
                  value="Save Changes"
                  className="btn btn-primary"
                />
              </form>
            </div>
          </div>
          </div>
        </div>
      </div>
                </>
              )}
            </Tab>

            <Tab eventKey="coordinators" title="Lab Co-ordinators">
                {activeTab === 'coordinators' && (
                  <LabCoordinators labcode={labcode} />
                )}
              </Tab>
              <Tab eventKey="appointments" title="Appointments">
                {activeTab === 'appointments' && (
                  <ViewAppointmentsByLabCorporate labcode={labcode} />
                )}
              </Tab>

              <Tab eventKey="invoices" title="Invoices">
                {activeTab === 'invoices' && (
                  <LabInvoices labcode={labcode} />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabsAndAppointmentsDetails;
