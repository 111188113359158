import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const AddFamily = ({ isOpen, onRequestClose, userid }) => {

  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;
  const [family, setFamily] = useState({
    name:"",
    lastname:"",
    aadhaarnumber:"",
    relationship:"",
    phoneNumber:"",
    gender:"",
    dob:"",
    address:"",
    pan:"",
    image:null,
    imageMeta:"",
    userid: userid,
  });
  const [loading, setLoading] = useState(false);
  const [relationship, setRelationship] = useState([]);
  const [marital, setMarital] = useState([]);

  const genderOptions = ['Male', 'Female'];

  const url = "/api/familydetails/create";
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductTypes();
  }, []);

  const fetchProductTypes = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get("/api/relationship", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRelationship(response.data);
      // const Response = await axios.get("/api/maritalstatus", {
      //   headers: { Authorization: `Bearer ${token}` },
      // });
      // setMarital(Response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.post(url, family, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("Family Details Added successfully");
      onRequestClose();
      setFamily("");
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const rhandle = (e) => {
    const { id, value, type, name } = e.target;
    setFamily((prevState) => ({
      ...prevState,
      [type === 'radio' ? name : id]: value,
    }));
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Family Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="name">Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      placeholder="Name"
                      value={family.name}
                      onChange={rhandle}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="dob">Date of Birth <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="dob"
                      type="date"
                      placeholder="Date Of Birth"
                      value={family.dob}
                      onChange={rhandle}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      className="form-control"
                      id="phoneNumber"
                      type="text"
                      placeholder="Phone Number"
                      value={family.phoneNumber}
                      onChange={rhandle}
                    
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="aadharnumber">Aadhaar Number </label>
                    <input
                      className="form-control"
                      id="aadhaarnumber"
                      type="text"
                      placeholder="Aadhaar Number"
                      value={family.aadhaarnumber}
                      onChange={rhandle}
                     
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pan">PAN </label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      value={family.pan}
                      onChange={rhandle}
                     
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gender">Gender <span style={{color:"red"}}>* </span></label>
                    <div id="gender">
                      {genderOptions.map((gender, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`gender-${gender}`}
                            name="gender"
                            value={gender}
                            checked={family.gender === gender}
                            onChange={rhandle}
                            required
                          />
                          <label className="form-check-label" htmlFor={`gender-${gender}`}>
                            {gender}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="relationship">Relationship Status <span style={{color:"red"}}>* </span></label>
                    <select
                      className="form-select"
                      id="relationship"
                      name="relationship"
                      value={family.relationship}
                      onChange={rhandle}
                      required
                    >
                      <option value="">Select relationship</option>
                      {relationship.map((type) => (
                        <option key={type.id} value={type.relationshipStatus}>
                          {type.relationshipStatus}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="address">Address</label>
                    <textarea
                      className="form-control"
                      id="address"
                      type="text"
                      placeholder="Address"
                      value={family.address}
                      onChange={(e) => rhandle(e)}
                      rows="2"
                 
                    />
                 
                </div>
                </div>
                <br />
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddFamily;
