import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "./Spinner.css";

const AddLabCoordinators = ({ isOpen, onRequestClose, labId, coOrdinator }) => {
  const navigate = useNavigate();
  const [coordinator, setCoordinator] = useState({
    coOrdinatorEmail: "",
    coOrdinatorName: "",
    coOrdinatorMobileno: "",
    labId: labId || "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (coOrdinator) {
      setCoordinator({
        coOrdinatorEmail: coOrdinator.coOrdinatorEmail,
        coOrdinatorMobileno: coOrdinator.coOrdinatorMobileno,
        coOrdinatorName: coOrdinator.coOrdinatorName,
        labId: labId, // Ensure labId is set here
      });
    } else {
      setCoordinator({
        coOrdinatorEmail: "",
        coOrdinatorMobileno: "",
        coOrdinatorName: "",
        labId: labId, // Set labId for new coordinators
      });
    }
  }, [coOrdinator, labId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const requestBody = { ...coordinator }; // Ensure labId is part of this object
      if (coOrdinator) {
        requestBody.id = coOrdinator.id; // Include id only if updating
        await axios.post(`/api/lab-coordinators/update`, requestBody, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Lab Co-ordinator updated successfully.");
      } else {
        await axios.post(`/api/lab-coordinators/create`, requestBody, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Lab Co-ordinator created successfully.");
      }
      onRequestClose();
      setCoordinator({
        coOrdinatorEmail: "",
        coOrdinatorName: "",
        coOrdinatorMobileno: "",
        labId: labId, // Reset with labId
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function rhandle(e) {
    const { id, value } = e.target;
    setCoordinator((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {coOrdinator ? "Update Co-ordinator" : "Add Co-ordinator"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="coOrdinatorEmail">
                      Co-ordinator Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      id="coOrdinatorEmail"
                      type="email"
                      placeholder="Co-ordinator Email"
                      value={coordinator.coOrdinatorEmail}
                      onChange={rhandle}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="coOrdinatorName">
                      Co-ordinator Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      id="coOrdinatorName"
                      type="text"
                      placeholder="Co-ordinator Name"
                      value={coordinator.coOrdinatorName}
                      onChange={rhandle}
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="coOrdinatorMobileno">
                      Co-ordinator Mobile Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="form-control"
                      id="coOrdinatorMobileno"
                      type="text"
                      placeholder="Co-ordinator Mobile Number"
                      value={coordinator.coOrdinatorMobileno}
                      onChange={rhandle}
                      required
                    />
                  </div>
                </div>
                <br />
                <input
                  type="submit"
                  value={coOrdinator ? "Update" : "Save"}
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default AddLabCoordinators;
