import React, { useEffect, useState } from "react";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from 'react-router';

const ArchiveEmployeeModal = ({ isOpen, onRequestClose, employeeId }) => {
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("data")).token;

  const handleArchive = async () => {
    setLoading(true);
    try {
      await axios.post(`/api/employeedetails/update`,
        {
            id:employeeId,
            archive:true
        },
        {
            headers: { Authorization: `Bearer ${token}` },
          }
      );
      toast.success("Employee Archived successfully")
      onRequestClose();
    } catch (error) {
        handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  return (
    <Modal show={isOpen} onHide={onRequestClose} centered >
    <Modal.Header closeButton>
      <Modal.Title>Archive Employee</Modal.Title>
    </Modal.Header>
 
      <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
        <p>You cannot delete this employee because doing so would result in data loss. Please archive the employee instead.</p>
   
 <br/>
 <div>
        <Button variant="secondary" className="me-4"onClick={onRequestClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleArchive}>
         ARCHIVE
        </Button>
        </div>
        </div>
        </div>
        </div>

        </Modal.Body>
    </Modal>
  );
};

export default ArchiveEmployeeModal;
