import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import LabIncomingInvoices from "./labIncomingInvoices";
import LabOutgoingInvoices from "./labOutgoingInvoices";

function LabInvoices({ labcode }) {
  const [activeTab, setActiveTab] = useState("incomingInvoices");

  return (
    <div className="col-12 mx-xl-auto ">
      <div className="card card-raised">
        <div className="card-body">
          <Tabs
            id="invoices-tabs"
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
            className="mb-3"
          >
            <Tab eventKey="incomingInvoices" title="Incoming Invoices">
            {activeTab === 'incomingInvoices' && <LabIncomingInvoices labcode={labcode} />}
            </Tab>
            <Tab eventKey="outgoingInvoices" title="Outgoing Invoices">
            {activeTab === 'outgoingInvoices' && <LabOutgoingInvoices labcode={labcode} />}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default LabInvoices;
