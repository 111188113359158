import React, { useState, useEffect } from "react";
import AdminDashboard from "../components/admindashboard";
import { Button, Form, Table, Container, Row, Col, Card } from "react-bootstrap";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import { FaTrash, FaEye, FaEyeSlash } from "react-icons/fa";

function Smtpconfigure() {
  const userid = JSON.parse(localStorage.getItem("data")).userid;
  const [smtpConfig, setSmtpConfig] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
    adminlink: "",
  });
  const [configs, setConfigs] = useState([]);
  const [accounts, setAccounts] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfigPasswords, setShowConfigPasswords] = useState({}); // For showing passwords in the table
  const [fromAddress, setFromAddress] = useState(""); // State for from address
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;

      const response = await axios.get(`/api/smtpconfiguration/adminlink=${userid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setConfigs(response.data);

      const accRes = await axios.get(`/api/accounts/${userid}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAccounts(accRes.data);
      setFromAddress(accRes.data.fromaddress);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error fetching configurations");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSmtpConfig((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      const smtpConfigWithFromAddress = {
        ...smtpConfig,
        fromaddress: fromAddress, 
      };

      await axios.post("/api/smtpconfiguration/create", smtpConfigWithFromAddress, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("SMTP configuration created successfully!");
      fetchConfigs();

      setSmtpConfig({
        host: "",
        port: "",
        username: "",
        password: "",
        adminlink: "",
      });
    } catch (error) {
      console.error(error);
      toast.error("Error creating SMTP configuration");
    }
  };

  const handleDelete = async (config) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this configuration?");
    if (confirmDelete) {
      try {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("data")).token;

        await axios.delete("/api/smtpconfiguration", {
          headers: { Authorization: `Bearer ${token}` },
          data: config,
        });

        toast.success("SMTP configuration deleted successfully!");
        
        fetchConfigs();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error deleting SMTP configuration");
      }
    }
  };

  // Function to handle saving From Address
  const handleSaveFromAddress = async () => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;

      // Make the API call to update the from address
      await axios.post("/api/accounts/update/fromaddress", { fromAddress }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("From Address updated successfully!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error updating from address", error);
      toast.error("Error updating From Address");
    }
  };

  const toggleConfigPasswordVisibility = (index) => {
    setShowConfigPasswords((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div>
      <br /><br /><br /><br />
      <AdminDashboard />
      {loading && <div className="loading"></div>}
      <Container className="mt-4">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <Row>
              <Col md={8}>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-center mb-4">SMTP Configuration</Card.Title>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="host">
                        <Form.Label>Host</Form.Label>
                        <Form.Control
                          type="text"
                          name="host"
                          value={smtpConfig.host}
                          onChange={handleChange}
                          required
                          className="w-100"
                        />
                      </Form.Group>

                      <Form.Group controlId="port">
                        <Form.Label>Port</Form.Label>
                        <Form.Control
                          type="number"
                          name="port"
                          value={smtpConfig.port}
                          onChange={handleChange}
                          required
                          className="w-100"
                        />
                      </Form.Group>

                      <Form.Group controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type="text"
                          name="username"
                          value={smtpConfig.username}
                          onChange={handleChange}
                          required
                          className="w-100"
                        />
                      </Form.Group>

                      <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <div className="position-relative">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={smtpConfig.password}
                            onChange={handleChange}
                            required
                            className="w-100"
                          />
                          <span
                            onClick={() => setShowPassword(!showPassword)}
                            className="position-absolute end-0 top-0 mt-2 me-3"
                            style={{ cursor: "pointer" }}
                          >
                            {showPassword ? (
                              <FaEyeSlash className="icon-size" />
                            ) : (
                              <FaEye className="icon-size" />
                            )}
                          </span>
                        </div>
                      </Form.Group>

                      <br />
                      <Button variant="primary" type="submit" className="w-100 custom-btn">
                        Create Configuration
                      </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className="mt-4">
                  <Card.Body>
                    <Card.Title className="text-center mb-4">From Address</Card.Title>
                    <Form.Group controlId="fromAddress">
                      <Form.Label>From Address</Form.Label>
                      <Form.Control
                        type="text"
                        value={fromAddress}
                        onChange={(e) => setFromAddress(e.target.value)}
                        placeholder="Enter From Address"
                        required
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      className="mt-3 w-100"
                      onClick={handleSaveFromAddress}
                    >
                      Save From Address
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <br />
            <Card>
              <Card.Body>
                <Card.Title className="text-center mb-4">Existing Configurations</Card.Title>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Host</th>
                      <th>Port</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {configs.length===0 ? (
                       <tr>
                       <td colSpan="10" className="text-center">
                         No Data Available
                       </td>
                     </tr>
                    ):(
                  configs.map((config, index) => (
                      <tr key={index}>
                        <td>{config.host}</td>
                        <td>{config.port}</td>
                        <td>{config.username}</td>
                        <td>
                          <div className="position-relative">
                            <span>
                              {showConfigPasswords[index] ? config.password : "••••••••"}
                            </span>
                            <span
                              onClick={() => toggleConfigPasswordVisibility(index)}
                              className="ms-2"
                              style={{ cursor: "pointer" }}
                            >
                              {showConfigPasswords[index] ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleDelete(config)}
                          >
                            <FaTrash />
                          </Button>
                        </td>
                      </tr>
                    )))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Smtpconfigure;
