import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import { toast } from "react-toastify";
import { BiSolidCheckCircle, BiSolidXCircle } from "react-icons/bi";
import TestForPackage from "./testsByPackagePopup";
import { FiInfo } from "react-icons/fi";
import { FaDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import tmplLogo from "../images/tmplLogo.png";
import FamilyAppointmentCancel from "./familyAppointmentCancelPopup";
import AppointmentReschedule from "./appointmentReschedule";
import { FaRedoAlt } from 'react-icons/fa';

function FamilyAppointments() {
    const {memberid} = useParams();
    const [appointmentDetails, setAppointmentDetails] = useState([]);
    const [recieptDataPopup, setRecieptDataPopup] = useState(false);
    const [appointmentData, setAppointmentData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [isReschedulePopupOpen, setIsReschedulePopupOpen] = useState(false);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);

    const navigate = useNavigate();
  
    const getData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoader(true);
      try {
        const response = await axios.get(`/api/employeeappointment/familyid=${memberid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAppointmentDetails(response.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        handleError(error);
      }
    };

    const [isEmployee, setIsEmployee] = useState(false);
  
    useEffect(() => {
      getData();
      const userData = JSON.parse(localStorage.getItem("data"));
      if (userData && userData.role === "ROLE_EMPLOYEE") {
        setIsEmployee(true);
      }
    }, [memberid]);
  
    const handleBookAppointment = () => {
      navigate(`/users/bookAnAppointmentForFamilyMember/${memberid}`);
    };
  
    const [pdfUrl, setPdfUrl] = useState(null);
    const handleViewPdf = async (appointmentId) => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      await axios({
        url: `/api/employeeappointment/download/id=${appointmentId}`,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      }).then((response) => {
        console.log("filedata;", response);
        const contentType = response.headers["content-type"];
        let extension = "";
    
        // Determine the file extension based on content type
        if (contentType.includes("application/pdf")) {
          extension = "pdf";
        } else if (contentType.includes("image/jpeg")) {
          extension = "jpg";
        } else if (contentType.includes("image/png")) {
          extension = "png";
        } else {
          console.error("Unsupported file type:", contentType);
          return;
        }
    
        // Create a blob URL and download link for the file
        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `file.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
    }

    const handleDownloadReciept = async (appointment) => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      try {
          // Fetch payment and user details
          const [paymentResponse, userResponse] = await Promise.all([
              axios.get(`/api/user/userpayment/getById/${appointment.paymentId}`, {
                  headers: { Authorization: `Bearer ${token}` },
              }),
              axios.get(`/api/employeedetails/userid=${appointment.userid}`, {
                  headers: { Authorization: `Bearer ${token}` },
              })
          ]);
  
          const paymentDetails = paymentResponse.data;
          const userDetails = userResponse.data;
   // Total amount
   const totalAmount = parseFloat(paymentDetails.paymentAmount);
   const platformFeeRate = 0.03;
   const platformGstRate = 0.18;

   // Calculate Actual Price
   const actualPrice = totalAmount / (1 + platformFeeRate * (1 + platformGstRate));

   // Calculate Platform Fee and Platform Fee GST
   const platformFee = actualPrice * platformFeeRate;
   const platformFeeGst = platformFee * platformGstRate;

   // Calculate other amounts (CGST and SGST are zero in this case)
   const cgst = 0;
   const sgst = 0;

   // Calculate final amounts for confirmation
   const calculatedTotalAmount = actualPrice + platformFee + platformFeeGst;


    // Create PDF document
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Define image properties
    const imageWidth = 40; // Example width in mm
    const imageHeight = 15; // Example height in mm
    const margin = 10; // Margin from the right edge

    // Calculate x-coordinate for the image
    const x = pageWidth - imageWidth - margin;
    
    // Add the image to the right end of the receipt
    doc.addImage(tmplLogo, 'PNG', x, 10, imageWidth, imageHeight);

    // Add title
    doc.setFontSize(16);
    doc.text("RECEIPT", 10, 20);

    // Add company details
    doc.setFontSize(12);
    doc.text("Timespan Pvt Ltd.", 10, 30);
    doc.text("Bangalore, Karnataka", 10, 40);
    doc.text("PIN: 123456", 10, 50); // Replace with actual PIN
    doc.text("GSTIN: 29AAJCC7045D1ZM", 10, 60);

    // Add Bill To section
    doc.text("Bill To", 10, 80);
    doc.text(userDetails.email, 10, 90);
    doc.text(userDetails.address, 10, 100);
    doc.text(`PAN: ${userDetails.pan}`, 10, 110);

    // Add Reference Number
    doc.text("Reference Number:", 10, 130);
    doc.text(paymentDetails.razorpay_payment_id, 60, 130);

    // Add horizontal line
   

    // Add Total Amount section
    doc.setFontSize(14);
    doc.text("Total Amount", 10, 150);
    doc.text(`${totalAmount.toFixed(2)} Rs`, pageWidth - 30, 150, { align: "right" });

    doc.setLineWidth(1);
    doc.line(10, 160, pageWidth - 10, 160);
    // Add Details Section
    doc.setFontSize(12);
    doc.text(`Amount: ${actualPrice.toFixed(2)}`, 10, 180);
    doc.text(`CGST: ${cgst.toFixed(2)}`, 10, 190);
    doc.text(`SGST: ${sgst.toFixed(2)}`, 10, 200);
    doc.text(`Platform Fee: ${platformFee.toFixed(2)}`, 10, 210);
    doc.text(`Platform Fee GST: ${platformFeeGst.toFixed(2)}`, 10, 220);
    doc.text(`Total Amount: ${totalAmount.toFixed(2)} Rs`, 10, 230);

    // Save the PDF
    doc.save("receipt.pdf");
} catch (error) {
    console.error("Error generating PDF: ", error);
    toast.error("Failed to generate receipt.");
}
    }
    // const handleDownloadReciept = async (appointmentData) => {
    //   setAppointmentData(appointmentData);
    //  setRecieptDataPopup(true);
    // }

    const handleCloseRecieptDataPopup=() =>{
setRecieptDataPopup(false);
    }
  
    function handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }  else if (error.response.data && error.response.data.code) {
          toast.error(error.response.data.code);
        }
         else {
          toast.error("An error occurred.");
        }
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  
    const [loading, setLoading] = useState(null);
    
function rsubmit(e, id, status) {
    e.preventDefault();
    setLoading(id);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    const entitiesToUpdate = ({
      id: id,
      reportstatus: status,
    });
  
    axios
      .post(
        "/api/employeeappointment/approve",
        entitiesToUpdate,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log(response.data);
        setLoading(null);
        getData();
      })
      .catch((error) => {
       handleError(error);
      });
  }

  const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);

  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packagename=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };
  
  const closeDenominationModal = () => {
    setIsTestsModalOpen(false);
   
  };

  const handleAcceptAppointment = async (appointmentId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      await axios.post(
        "/api/employeeappointment/accept-or-reschedule",
        {
          id: `${appointmentId}`,
          appointmentstatus: "APPOINTMENT CONFIRMED",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Employee Appointment Confirmed successfully.");
      getData();
      setLoader(false);
    } catch (error) {
    setLoader(false);
      handleError(error);
 
    }
  };

  const handleRescheduleAppointment = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsReschedulePopupOpen(true);
  };

  const handleCloseReschedulePopup = () => {
    setIsReschedulePopupOpen(false);
    getData();
  };

  const handleCancelClick = async (appointment) => {
    setSelectedAppointment(appointment)
    setIsCancelPopupOpen(true);
  };
  
  const handleCloseCancelPopup = () => {
    setIsCancelPopupOpen(false);
    getData();
  };
  const handleBack = () => {
    navigate(-1);
  };

  const handleRetry = async (appointment) => {
    setLoader(true); 
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      await axios.post('/api/employeeappointment/re-trigger-api', appointment, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Retried successfully');
      getData();
    } catch (error) {
      toast.error('Retry failed');
    } finally {
      setLoader(false); 
    }
  };

  const handleFetchReport= async (appointment) => {
    setLoader(true); 
    const token = JSON.parse(localStorage.getItem('data')).token;

    try {
      await axios.post('/api/employeeappointment/fetchReport', appointment, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Report fetched successfully');
      getData();
    } catch (error) {
     handleError(error);
    } finally {
      setLoader(false); 
    }
  };
    
    return (
      <div>
       
        <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="mb-2">  
        <button className="btn btn-primary" onClick={handleBack}>
      Back
    </button>
        </div>
          <div className="card card-raised">
            <div className="card-body">
              <div className="card-title form-group d-flex align-items-center justify-content-between">
                <span>Appointment Details</span>
                {isEmployee && (
                <button className="btn btn-primary" onClick={handleBookAppointment}>
                  Book Appointment
                </button>
                )}
              </div>
              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Date</th>
                    {/* <th>Time</th> */}
                    <th>Lab Name</th>
                    <th>Lab Contact Number</th>
                    <th>Package Name</th>
                    <th>Status</th>
                    <th>Report</th>
                    {JSON.parse(localStorage.getItem("data")).role==="ROLE_ADMIN" || JSON.parse(localStorage.getItem("data")).role==="ROLE_SUPER" && (
                    <th>Report Status</th>
                    )}
                     
                    <th>Reciept</th>
                    <th>Lab Order Status</th>

                  </tr>
                </thead>
                <tbody>
                  {appointmentDetails.length === 0 ? (
                    <tr>
                      <td colSpan="10" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    appointmentDetails.map((appointment) => (
                      <tr key={appointment.id}>
                        <td>{appointment.date}</td>
                        {/* <td>{appointment.time}</td> */}
                        <td>{appointment.labs?.labname}</td>
                        <td>{appointment.labs?.contact}</td>
                        <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{appointment.pack?.packagename}</span>
              <FiInfo
                 onClick={() => openTestsModal(appointment.pack?.packagename)}
                style={{
                  cursor: 'pointer'
                }}
              />
                      </td>
                      <td>
  <div>{appointment.appointmentstatus}</div>
  {(appointment.appointmentstatus!=='APPOINTMENT CANCELLED'||appointment.appStatus?.appointmentStatus !== 'APPOINTMENT CANCELLED') && (appointment.appointmentstatus!=='APPOINTMENT COMPLETED'||appointment.appStatus?.appointmentStatus !== 'APPOINTMENT COMPLETED') && (
  // {appointment.appointmentstatus !== 'APPOINTMENT CANCELLED' && appointment.appointmentstatus !== 'APPOINTMENT COMPLETED' && (
  <>
    <div>
      {/* Show the Accept button if the appointment is rescheduled by Lab and not yet confirmed */}
      {appointment.appStatus?.appointmentStatus !== 'APPOINTMENT CONFIRMED' && 
        appointment.appStatus?.appointmentStatus === 'APPOINTMENT RESCHEDULED' && 
        appointment.appStatus?.updatedBy !== 'Employee' && (
        <button
          className="btn btn-success btn-sm rounded-pill me-2"
          onClick={() => handleAcceptAppointment(appointment.id)}
        >
          Accept
        </button>
      )}
      
      {/* Cancel button, displayed only if the appointment is neither CANCELLED nor COMPLETED */}
      <button
        className="btn btn-danger btn-sm rounded-pill"
        onClick={() => handleCancelClick(appointment)}
      >
        Cancel
      </button>
    </div>
    
    <div className="mt-2">
      <button
        className="btn btn-warning btn-sm rounded-pill"
        onClick={() => handleRescheduleAppointment(appointment.id)}
      >
        Reschedule
      </button>
    </div>
  </>
)}

</td>
<td>
  {appointment.orderStatus ? (
    appointment.orderStatus?.appointmentReport ? (
      <div>
        {/* Display the appointment report if available */}
        <span>{appointment.orderStatus.appointmentReport}</span>
      </div>
    ) : (
      <button
        onClick={() => handleFetchReport(appointment)}
      >
        Fetch Report
      </button>
    )
  ) : (
    appointment.file ? (
      appointment.reportstatus === 'APPROVED' ? (
        <button
          className="btn btn-primary"
          onClick={() => handleViewPdf(appointment.id)}
        >
          View
        </button>
      ) : (
        "Approval Pending"
      )
    ) : (
      "No Report Added"
    )
  )}
</td>
                        {JSON.parse(localStorage.getItem("data")).role==="ROLE_ADMIN" || JSON.parse(localStorage.getItem("data")).role==="ROLE_SUPER"&& (
                        <td>
                        {appointment.file ? (
                          <>
                            {appointment.reportstatus === "REQUESTED" ? (
                              <td id="icon" value={appointment.reportstatus}>
                                &nbsp;
                                {appointment.reportstatus} &nbsp;
                                {loading && (
                                  <div
                                    className="loader"
                                    style={{
                                      position: "relative",
                                      left: "75px",
                                      top: "10px",
                                    }}
                                  ></div>
                                )}{" "}
                                <BiSolidCheckCircle
                                  className="icon"
                                  id="appointment.reportstatus"
                                  style={{ backgroundColor: "green" }}
                                  value="APPROVED"
                                  onClick={(e) =>
                                    rsubmit(e, appointment.id, "APPROVED")
                                  }
                                />
                                &ensp;
                                <BiSolidXCircle
                                  className="icon"
                                  value="REJECTED"
                                  style={{ backgroundColor: "red" }}
                                  id="appointment.reportstatus"
                                  onClick={(e) =>
                                    rsubmit(e, appointment.id, "REJECTED")
                                  }
                                />
                              </td>
                            ) : (
                              <td id="icon" value={appointment.reportstatus}>
                                {" "}
                                {appointment.reportstatus}
                              </td>
                            )}
                          </>
                        ) : (
                          "No Report Added"
                        )}
                      </td>
                        )}
                        <td style={{cursor:"pointer"}} title="Download Reciept" onClick={() => handleDownloadReciept(appointment)}><FaDownload/></td>
                        <td>
  <div>{appointment.orderStatus?.status}</div>

  <div>
    {appointment.orderStatus ? (
      <>
      <br/>
        {appointment.orderStatus?.orderId === "" || appointment.orderStatus?.orderId == null ? (  // Check for both empty string and null/undefined
          <>
            <button
              onClick={() => handleRetry(appointment)}
              style={{ cursor: 'pointer', color: 'red' }}
            >
              Retry
              <FaRedoAlt/>
            </button>
          </>
        ) : (
          <div>{appointment.orderStatus?.orderId}</div>
        )}
      </>
    ) : (
      <div>NA</div>
    )}
  </div>
</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>

            {isTestsModalOpen && (
<TestForPackage
        isOpen={isTestsModalOpen}
        onRequestClose={closeDenominationModal}
        testDetails={tests}
      />
)}
{isCancelPopupOpen &&(
   <FamilyAppointmentCancel
   isOpen={isCancelPopupOpen}
   onRequestClose={handleCloseCancelPopup}
   appointment={selectedAppointment}
 />
)}
  {isReschedulePopupOpen && (
          <AppointmentReschedule
            isOpen={isReschedulePopupOpen}
            onRequestClose={handleCloseReschedulePopup}
            appointmentId={selectedAppointmentId}
          />
        )}
          </div>
          {loader && <div className="loading"></div>}
        </div>
      </div>
    );
  }
  

export default FamilyAppointments;