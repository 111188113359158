import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import CreateYearSettings from "./addYearSettings";

function FinancialYearSettings() {
  const [financialYear, setFinancialYear] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null); 
  const [selectedYear, setSelectedYear] = useState(null); 
  const token = JSON.parse(localStorage.getItem("data")).token;
  const orgCode = JSON.parse(localStorage.getItem("data")).orgcode;
  const [selectedId, setSelectedId] = useState(null);

  const getData = async () => {
    setLoading(true);

    try {
      const performanceTargetResponse = await axios.get(`/api/year-settings`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    
      setFinancialYear(performanceTargetResponse.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    getData();
    setModalData([]);
  };

  const handleAddProduct = () => {
    setIsModalOpen(true);
    setFinancialYear([]);
  };

  const handleAddCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  // let date = new Date();
  // let month = date.getMonth(); // This returns the current month as a number (0-11)
  // let year = date.getFullYear(); // This returns the current year as a number

  // let currentYear = year;
  // let nextYear = year + 1;

  // // If the month is January (0), February (1), or March (2)
  // if (month <= 2) {
  //   currentYear = year - 1;
  //   nextYear = year;
  // }

  return (
    <div>
      <AdminDashboard />
      <div className="col-10 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            {loading && <div className="loading"></div>}
            <div className="card-title form-group d-flex align-items-center justify-content-between">Finacial Year Settings
           <button className="btn btn-primary" onClick={handleAddProduct}>
           {financialYear.length > 0 ? "Update" : "Add"}
           </button> 
         </div>
            <br />
            <table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>From Date</th>
                  <th>To Date</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {financialYear.length === 0 ? (
                  <tr>
                    <td colSpan="2" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  financialYear.map((financiaYear, index) => {
                    // let date = new Date();
                    // let currentMonth = date.getMonth() + 1; // Current month as number (1-12)
                    // let productFromMonth = monthMap[currentProduct.fromDate]; // Convert month name to number

                    // // Default year values
                    // let currentYear = date.getFullYear();
                    // let nextYear = currentYear + 1;

                    // if (currentMonth < productFromMonth) {
                    //   currentYear -= 1;
                    //   nextYear = currentYear + 1;
                    // }

                    return (
                      <tr key={financiaYear.id}>
                            <td>{financiaYear.fromDate} </td>
                            <td>{financiaYear.toDate}</td>
                            {/* <td>
                             
                            </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <CreateYearSettings isOpen={isModalOpen} onRequestClose={handleAddCloseModal} 
        />
      )}
    </div>
  );
}

export default FinancialYearSettings;
