import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";

const UploadCompaniesCsvFile = ({ isOpen, onRequestClose }) => {
  const date = new Date();
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;

  const [companies, setCompanies] = useState({
    file: null,
  });

  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [sendNotification, setSendNotification] = useState(false); // New state for notification

  const url = "/api/companydetails/upload";
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    console.log("CSV File:", csvFile);

    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("sendNotification", sendNotification); // Append sendNotification

    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      setLoading(false);
      toast.success("CSV file uploaded successfully");
      onRequestClose();
      setCompanies("");
      setCsvFile(null);
      setSendNotification(false); // Reset notification checkbox after success
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleCsvFileChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);

    const formData = new FormData();
    formData.append("file", file);

    console.log(formData);
  };

  const handleSendNotificationChange = (e) => {
    setSendNotification(e.target.checked); // Update sendNotification state when checkbox is changed
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload Company CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col">
                    <label htmlFor="file" className="me-1">
                      Upload File:
                    </label>
                    <input
                      className="form-control smaller-input"
                      id="file"
                      type="file"
                      placeholder="Upload document"
                      onChange={handleCsvFileChange}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-auto">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sendNotification"
                        checked={sendNotification}
                        onChange={handleSendNotificationChange} // Checkbox for sendNotification
                      />
                      <label className="form-check-label" htmlFor="sendNotification">
                        Send Notification
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary"
                      disabled={loading}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="secondary" onClick={onRequestClose}>
                      Close
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default UploadCompaniesCsvFile;
