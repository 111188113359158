import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";

const AddInvoicesByCorporate = ({ isOpen, onRequestClose}) => {
  const navigate = useNavigate();
  
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      

  const url = "/api/invoices/create";
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  const [invoices, setInvoices] = useState({
    date: currentDate,
    startdate: "",
    enddate: "",
    labcode: "",
    grossamount: "",
    tax: "",
    netpay: "",
    invoicenumber: "",
    file: null,
    fileContentType: "",
    fileSize:"",
    createdBy:"",
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    const userRole = JSON.parse(localStorage.getItem("data")).role; // Assuming the role is saved in the 'data' object

    // Set createdBy based on the role
    let createdBy = "";
    if (userRole === "ROLE_ADMIN") {
      createdBy = "Admin"; // Set as Admin if the role is admin
    } else if (userRole === "ROLE_LAB" || userRole === "ROLE_LAB_CORPORATE") {
      createdBy = "Lab"; // Set as Company if the role is employer
    }else if (userRole === "ROLE_SUPER") {
      createdBy = "Super"; // Set as Company if the role is employer
    }
    if (
      invoices.date &&
      invoices.startdate &&
      invoices.enddate &&
      invoices.grossamount &&
      invoices.netpay &&
      invoices.invoicenumber
    ) {
      // const period = `${invoices.startdate} to ${invoices.enddate}`;

      axios
        .post(
          url,
          {
            date: invoices.date,
            startdate: invoices.startdate,
            enddate:invoices.enddate,
            labcode: invoices.labcode,
            grossamount: invoices.grossamount,
            tax: invoices.tax,
            netpay: invoices.netpay,
            invoicenumber: invoices.invoicenumber,
            file: invoices.file,
            fileContentType: invoices.fileContentType,
            fileSize:invoices.fileSize,
            createdBy:createdBy

          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
        .then((res) => {
          setLoading(false);
          onRequestClose();
          setInvoices("");
          toast("Invoice Added Successfully");
        })
        .catch((error) => {
          handleError(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error("Please fill out all the required fields.");
    }
  };

  const [labDetails, setLabDetails] = useState([]);
  const [taxes, setTaxes] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const response = await axios.get(`/api/labdetails/linkedLabsByCorporateOrgcode/orgcode=${orgcode}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setLabDetails(response.data);

        const taxRes = await axios.get("/api/tax", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTaxes(taxRes.data);
      } catch (error) {
        handleError(error);
      }
    };
    fetchData();
  }, []);

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  const handleChange = (e) => {
    const { id, value } = e.target;
    setInvoices((prevState) => ({
      ...prevState,
      [id]: value
    }));

    if (id === "grossamount" || id === "tax") {
      calculateNetPay({ ...invoices, [id]: value });
    }
  };

  const calculateNetPay = ({ grossamount, tax }) => {
    const grossAmountValue = parseFloat(grossamount) || 0;
    const taxValue = parseFloat(tax) || 0;
    const netPay = grossAmountValue + (grossAmountValue * taxValue) / 100;
    setInvoices((prevState) => ({
      ...prevState,
      netpay: netPay.toFixed(2)
    }));
  };

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...invoices,

      file: convertedPdf.content,
      fileSize: file.size,
      fileContentType:convertedPdf.metadata
    };

    setInvoices(pdfObject);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Invoices</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-2">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">
                <div className="form-floating-sm mb-2">
                    <label htmlFor="labcode">
                      Lab <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="labcode"
                      value={invoices.labcode}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value="">Select Lab</option>
                      {labDetails.map((lab) => (
                        <option key={lab.id} value={lab.labcode}>
                          {lab.labname}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="date">
                        Date <span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="date"
                        type="date"
                        placeholder="Date"
                        value={invoices.date}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="startDate">
                        Start Date <span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="startdate"
                        type="date"
                        placeholder="Start Date"
                        value={invoices.startdate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="endDate">
                        End Date <span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="enddate"
                        type="date"
                        placeholder="End Date"
                        value={invoices.enddate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="grossamount">
                        Gross Amount <span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="grossamount"
                        type="text"
                        placeholder="Gross Amount"
                        value={invoices.grossamount}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                <div className="form-floating-sm mb-2">
                    <label htmlFor="tax">
                      Tax (%) <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="tax"
                      value={invoices.tax}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value="">Select Tax</option>
                      {taxes.map((t) => (
                        <option key={t.id} value={t.tax}>
                          {t.tax}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="netpay">
                        Net Pay <span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="netpay"
                        type="text"
                        placeholder="Net Pay"
                        value={invoices.netpay}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="invoicenumber">
                        Invoice Number <span style={{ color: "red" }}>* </span>
                      </label>
                      <input
                        className="form-control"
                        id="invoicenumber"
                        type="text"
                        placeholder="Invoice Number"
                        value={invoices.invoicenumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="file" className="me-1">Upload File:</label>
                    <input
                                  className="form-control smaller-input flex-grow-1"
                                  id="file"
                                  type="file"
                                  placeholder="Upload document"
                                  onChange={(e) => handlePdfFileChange(e)}
                                />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Button type="button" className="btn btn-secondary" onClick={onRequestClose}>
                    Close
                  </Button>
                  <Button type="submit" className="btn btn-primary ms-2">
                    Save
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddInvoicesByCorporate;
