import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { BiEditAlt } from "react-icons/bi";
import { Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap

function LabCorporateAdminLink({ selectedOrgcode }) {
  const [adminLink, setAdminLink] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [labCorporate, setLabCorporate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]); // State for storing accounts

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/lab-corporate/orgcode=${selectedOrgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdminLink(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchAccounts = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/accounts/role=ROLE_ADMIN`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAccounts(response.data); // Assuming response.data contains an array of accounts
    } catch (error) {
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleEditLocation = (adminLink) => {
    setLabCorporate(adminLink);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      const response = await axios.post('/api/lab-corporate/update',
        {
          id: labCorporate.id,
          adminlink: labCorporate.adminEmail // Update with the selected email from the form
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      toast.success("Admin Link updated successfully");
      handleCloseModal(); // Close the modal after successful update
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
    fetchAccounts(); // Fetch accounts when the component mounts
  }, []);

  return (
    <div>
      <div className="company-profile">
        <div className="card card-raised mb-5">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>Admin Link</span>
            </div>
            <br />
            <table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>Admin Email</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {adminLink?.adminlink === null ? (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  <tr key={adminLink?.id}>
                    <td>{adminLink?.adminEmail}</td>
                    <td style={{ fontSize: "17px", cursor: "pointer" }}>
                      <button
                        onClick={() => handleEditLocation(adminLink)}
                        style={{ border: "none", background: "white", size: "150px" }}
                      >
                        <BiEditAlt />
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {isModalOpen && (
              <Modal show={isModalOpen} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Update Admin Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {loading && <div className="loading"></div>}
                  <form onSubmit={handleSubmit}>
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="emailSelect" className="me-1">Select Email:</label>
                      <select
                        id="emailSelect"
                        className="form-select"
                        value={labCorporate?.adminEmail || ""}
                        onChange={(e) => setLabCorporate({ ...labCorporate, adminEmail: e.target.value })}
                      >
                        <option value="">Select an email</option>
                        {accounts.map((account) => (
                          <option key={account.id} value={account.id}>
                            {account.username}
                          </option>
                        ))}
                      </select>
                    </div>
                    <br/>
                    <input
                      type="submit"
                      value="SAVE"
                      className="btn btn-primary me-4"
                      disabled={loading || !labCorporate?.adminEmail}
                    />
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </form>
                </Modal.Body>
                <Modal.Footer />
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabCorporateAdminLink;
