import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';  
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import ViewIncomingInvoicesByLabCorporate from "./viewIncomingInvoicesByLabcorporate";
import ViewOutgoingInvoicesByLabCorporate from "./viewOutgoingInvByLabCorporate";


function LinkedLabInvoices() {
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  const [activeTab, setActiveTab] = useState('incomingInvoices');

  useEffect(() => {
    // Any additional side effects (if needed) when tab changes can be handled here
  }, [activeTab]); // This effect will run when the activeTab changes

  return (
    <div>
      <AdminDashboard />
  
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
          <Tabs
            id="invoices-tabs"
            activeKey={activeTab}
            onSelect={(tab) => setActiveTab(tab)}
            className="mb-3"
          >
            <Tab eventKey="incomingInvoices" title="Incoming Invoices">
            {activeTab === 'incomingInvoices' && <ViewIncomingInvoicesByLabCorporate labcode={orgcode} />}
            </Tab>
            <Tab eventKey="outgoingInvoices" title="Outgoing Invoices">
            {activeTab === 'outgoingInvoices' && <ViewOutgoingInvoicesByLabCorporate labcode={orgcode} />}
            </Tab>
          </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkedLabInvoices;
