import React, { useEffect, useState } from "react";
import {Tab, Tabs } from 'react-bootstrap';  
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import CompanyProfile from "./companyProfile";
import EmployeesDetails from "./EmployeesDetails";
import Designations from "./Designations";
import InvoicesForCompany from "./invoicesForCompany";
import CompanyDoctorsDetails from "./companyDoctorsDetails";
import GeoLocations from "./geoLocations";
import LabCorporateProfile from "./labCorporateProfile";
import ViewPackages from "./viewPackagesTab";
import ApiUrlSettings from "./apiUrlSettings";
import LabCorporateAdminLink from "./labCorporateAdminLink";
 
  function LabCorporateProfileTab() {

  const { orgcode } = useParams();

  const userRole = JSON.parse(localStorage.getItem("data")).role;
  const[loading ,setLoading]=useState(false);
  const [activeTab, setActiveTab] = useState('corporateprofile');
  // const [selectedOrgcode, setSelectedOrgcode] = useState(orgcode);

  useEffect(() => {
  
  }, []);

  

  return (
    <div>
      <AdminDashboard />
      <br/>
              <br/>
              <br/>
      <br />


<div className="col-12 mx-xl-auto ps-xl-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="consumer-bio-tabs"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="corporateprofile" title="Corporate Profile">
              {activeTab === 'corporateprofile' && (
                <LabCorporateProfile selectedOrgcode={orgcode} />
              )}
              </Tab>
              <Tab eventKey="packages" title="Packages">
              {activeTab === 'packages' && (
                <ViewPackages selectedOrgcode={orgcode}/>
              )}
              </Tab>

              {userRole==='ROLE_SUPER' && (
              <Tab eventKey="settings" title="Settings">
              {activeTab === 'settings' && (
                <ApiUrlSettings selectedOrgcode={orgcode}/>
              )}
              </Tab>
              )}

{userRole==='ROLE_SUPER' && (
              <Tab eventKey="adminLink" title="Admin Link">
              {activeTab === 'adminLink' && (
                <LabCorporateAdminLink selectedOrgcode={orgcode}/>
              )}
              </Tab>
              )}

            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default LabCorporateProfileTab;