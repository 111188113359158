import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiInfo } from "react-icons/fi";
import TestForPackage from "./testsByPackagePopup";
import { Pagination } from "react-bootstrap";

function ViewAppointmentsByEmployer() {
    const { userid } = useParams();
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);

  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const searchUrl = `/api/employeeappointment/userid=${userid}`;
      const response = await axios.get(searchUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setAppointmentDetails(response.data);
      // setFilteredData(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const getData = async (pageNumber = 0) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const trans = await axios.get(`/api/employeeappointment/getAllAppointmentsByUserid/userid=${userid}`, {
        params: { page: pageNumber },
        headers: { Authorization: `Bearer ${token}` },
      });

      setAppointmentDetails(trans.data.content);
      // setFilteredData(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };


const [pdfUrl, setPdfUrl] = useState(null);
const handleViewPdf = async (appointmentId) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  await axios({
    url: `/api/employeeappointment/download/id=${appointmentId}`,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    console.log("filedata;", response);
    const contentType = response.headers["content-type"];
    let extension = "";

    // Determine the file extension based on content type
    if (contentType.includes("application/pdf")) {
      extension = "pdf";
    } else if (contentType.includes("image/jpeg")) {
      extension = "jpg";
    } else if (contentType.includes("image/png")) {
      extension = "png";
    } else {
      console.error("Unsupported file type:", contentType);
      return;
    }

    // Create a blob URL and download link for the file
    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `file.${extension}`);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}

const handleBack = async (orgcode) => {
  navigate(`/users/companydetails/${orgcode}`);
};

const [loading, setLoading] = useState(null);
function handleError(error) {
  if (error.response) {
    if (error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else if (error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    }  else if (error.response.data && error.response.data.code) {
      toast.error(error.response.data.code);
    }
     else {
      toast.error("An error occurred.");
    }
  } else if (error.message) {
    toast.error(error.message);
  } else {
    toast.error("An error occurred.");
  }
}

const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);

  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packageid=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };
  
  const closeDenominationModal = () => {
    setIsTestsModalOpen(false);
   
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };



  return (
    <div>
      <div className="col-12 mx-xl-auto ps-xl-10">
        <div className="mb-2">
      <button className="btn btn-primary" onClick={() => handleBack(appointmentDetails.companycode)}>
      Back
    </button>
    </div>
        <div className="card card-raised">
          <div className="card-body">
            <div className="d-flex">
             
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                <th>Employee Name</th>
                  <th>Date</th>
                  <th>Lab Name</th>
                  <th>Contact Number</th>
                  <th>Package Name</th>
                  <th>Appointment Status</th>
                  <th>Report</th>
            
                </tr>
              </thead>
              <tbody>
                {appointmentDetails.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  appointmentDetails.map((appointment) => (
                    <tr key={appointment.id}>
                        <td>{appointment.emplo?.name} {appointment.emplo?.lastname}</td>
                      <td>{appointment.date}</td>
                      <td>{appointment.labs?.labname}</td>
                      <td>{appointment.contact}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{appointment.pack?.packagename}</span>
              <FiInfo
                 onClick={() => openTestsModal(appointment.packageid)}
                style={{
                  cursor: 'pointer'
                }}
              />
                      </td>
                      <td> {appointment.appStatus?.appointmentStatus === "APPOINTMENT RESCHEDULED" ? (
    `Appointment Rescheduled on ${appointment.appStatus?.dateTime} by ${appointment.appStatus?.updatedBy}`
  ) : appointment.appStatus?.appointmentStatus === "APPOINTMENT CANCELLED" ? (
    `Appointment Cancelled on ${appointment.appStatus?.dateTime} by ${appointment.appStatus?.updatedBy}`
  ) : 
  appointment.appStatus?.appointmentStatus === "APPOINTMENT CONFIRMED" ? (
    `Appointment Confirmed on ${appointment.appStatus?.dateTime} by ${appointment.appStatus?.updatedBy}`
  ) : (
    appointment.appStatus?.appointmentStatus
  )}
  </td>
                      <td>
  {appointment.file ? (
    appointment.reportstatus === 'APPROVED' ? (
      <button
        className="btn btn-primary"
        onClick={() => handleViewPdf(appointment.id)}
      >
        View
      </button>
    ) : (
      "Approval Pending"
    )
  ) : (
    "No Report Added"
  )}
</td>
                                 
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div>
        </div>
        {isTestsModalOpen && (
<TestForPackage
        isOpen={isTestsModalOpen}
        onRequestClose={closeDenominationModal}
        testDetails={tests}
      />
)}
        {loader && <div className="loading"></div>}
      </div>
    </div>
    </div>
  );
}

export default ViewAppointmentsByEmployer;
