// EncryptionService.js
import { useEffect, useState } from "react";
import forge from 'node-forge';
import axios from "../api/baseurl";

const SecretKeyDecryption = () => {
  const [rsaKeyPair, setRsaKeyPair] = useState(null);
  const [rsaPublicKey, setRsaPublicKey] = useState(null);
  const [rsaPrivateKey, setRsaPrivateKey] = useState(null);

  useEffect(() => {
    const getSecretKey = async () => {
      await generateRsaKeyPair();
      await fetchSecretKey(); // Call it after key generation
    };
    getSecretKey();
  }, []);
  

  const generateRsaKeyPair = async () => {
    try {
      const keyPair = await window.crypto.subtle.generateKey(
        {
          name: "RSA-OAEP",
          modulusLength: 2048,
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"]
      );

      const publicKeyBuffer = await window.crypto.subtle.exportKey("spki", keyPair.publicKey);
      const privateKeyBuffer = await window.crypto.subtle.exportKey("pkcs8", keyPair.privateKey);
      setRsaKeyPair(keyPair);
      setRsaPublicKey(convertArrayBufferToPem(publicKeyBuffer, "PUBLIC KEY"));
      setRsaPrivateKey(convertArrayBufferToPem(privateKeyBuffer, "PRIVATE KEY").trim());
    } catch (error) {
      console.error("Error generating RSA key pair:", error);
    }
  };

  const convertArrayBufferToPem = (buffer, keyType) => {
    const byteArray = new Uint8Array(buffer);
    const base64String = window.btoa(String.fromCharCode(...byteArray));
    const formattedKey = base64String.match(/.{1,64}/g).join('\n');
    return `-----BEGIN ${keyType}-----\n${formattedKey}\n-----END ${keyType}-----`;
  };

  const fetchSecretKey = async () => {
    if (!rsaKeyPair) throw new Error("RSA key pair not generated");

    const credentials = `${process.env.REACT_APP_SUPER_USERNAME}:${process.env.REACT_APP_SUPER_PASSWORD}`;
    const base64Credentials = window.btoa(credentials);
    const response = await axios.post(
      "/api/encryption/secretkey",
      { publicKey: rsaPublicKey },
      {
        headers: {
          Authorization: `Basic ${base64Credentials}`,
        },
      }
    );

    const encryptedSecretKeyBase64 = response.data;
    return await decryptWithPEMPrivateKey(encryptedSecretKeyBase64, rsaPrivateKey);
  };

  const decryptWithPEMPrivateKey = async (encryptedSecretKeyArrayBuffer, privateKeyPem) => {
    const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
    const encryptedBytes = forge.util.decode64(encryptedSecretKeyArrayBuffer);
    return privateKey.decrypt(encryptedBytes);
  };

  return { fetchSecretKey };
};

export default SecretKeyDecryption;
