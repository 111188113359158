import React, { useEffect, useState } from "react";
import {Tab, Tabs } from 'react-bootstrap';  
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./admindashboard";
import IncomingAdminInvoices from "./incomingAdminInvoices";
import OutgoingAdminInvoices from "./outgoingAdminInvoices";
 
  function AdminInvoices() {

  const [activeTab, setActiveTab] = useState('incomingInvoices');

  useEffect(() => {
  
  }, []);

  return (
    <div>
      <AdminDashboard />
<div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="invoices-tabs"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)}
              className="mb-3"
            >
              <Tab eventKey="incomingInvoices" title="Incoming Invoices">
              {activeTab === 'incomingInvoices' && (
                <IncomingAdminInvoices/>
              )}
              </Tab>
              <Tab eventKey="outgoingInvoices" title="Outgoing Invoices">
              {activeTab === 'outgoingInvoices' && (
                <OutgoingAdminInvoices/>
              )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default AdminInvoices;