import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import AddInvoicesByCorporate from "./addInvoicesByCorporateForLabs";

function ViewOutgoingInvoicesByLabCorporate() {
    const [invoices, setInvoices] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [selectedLab, setSelectedLab] = useState(null);
    const [invoiceNumberFilter, setInvoiceNumberFilter] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [labs, setLabs] = useState([]);
    const [statusFilter, setStatusFilter] = useState("");
    const navigate = useNavigate();
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
  
    const getData = async () => {
      const token = JSON.parse(localStorage.getItem("data")).token;
      setLoader(true);
      try {
        const response = await axios.get(`/api/labdetails/linkedLabsByCorporateOrgcode/orgcode=${orgcode}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setLabs(response.data);
        const trans = await axios.get(`/api/invoices/getByLabcorporateOrgcode/${orgcode}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setInvoices(trans.data);
        setFilteredInvoices(trans.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        handleError(error);
      }
    };
  
    const handleCreateInvoices = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
      getData();
    };
  
    useEffect(() => {
      getData();
    }, []);
  
    // const handleSearch = (e) => {
    //   const term = e.target.value.toLowerCase();
    //   setSearchTerm(term);
    //   const filtered = companyDetails.filter(
    //     (company) =>
    //       company.companyname.toLowerCase().includes(term) || company.companycode.toLowerCase().includes(term)
    //   );
    //   setFilteredCompany(filtered);
    // };

      
const [pdfUrl, setPdfUrl] = useState(null);
const handleViewPdf = async (appointmentId) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  await axios({
    url: `/api/invoices/download/id=${appointmentId}`,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    console.log("filedata;", response);
    const contentType = response.headers["content-type"];
    let extension = "";

    // Determine the file extension based on content type
    if (contentType.includes("application/pdf")) {
      extension = "pdf";
    } else if (contentType.includes("image/jpeg")) {
      extension = "jpg";
    } else if (contentType.includes("image/png")) {
      extension = "png";
    } else {
      console.error("Unsupported file type:", contentType);
      return;
    }

    // Create a blob URL and download link for the file
    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `file.${extension}`);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}

function handleError(error) {
  if (error.response) {
    if (error.response.status === 401) {
      navigate(`/signin`);
      toast.error("Your session has expired. Please login.");
    } else if (error.response.data && error.response.data.message) {
      toast.error(error.response.data.message);
    }  else if (error.response.data && error.response.data.code) {
      toast.error(error.response.data.code);
    }
     else {
      toast.error("An error occurred.");
    }
  } else if (error.message) {
    toast.error(error.message);
  } else {
    toast.error("An error occurred.");
  }
}

const handleSearchInvoiceNumber = (event) => {
  const searchTerm = event.target.value.toLowerCase();
  setInvoiceNumberFilter(searchTerm);
  setSelectedDate(null); // Clear date filter when searching by invoice number
  filterInvoices(searchTerm, null, statusFilter);
};

const handleDateChange = (date) => {
  setSelectedDate(date);
  setInvoiceNumberFilter(""); // Clear invoice number filter when searching by date
  filterInvoices("", date, statusFilter);
};

const handleStatusFilterChange = (e) => {
  const status = e.target.value;
  setStatusFilter(status);
  filterInvoices(invoiceNumberFilter, selectedDate, status);
};

const handleLabFilterChange = (event) => {
    const labname = event.target.value;
    setSelectedLab(labname === "all" ? null : labname); // If "all" is selected, set labname to null
    filterInvoices(invoiceNumberFilter, selectedDate, statusFilter, labname === "all" ? null : labname); // Update filter logic to pass the lab name
};

const filterInvoices = (invoiceNumber, date, status, labname) => {
  let filtered = invoices;

  if (invoiceNumber) {
    filtered = filtered.filter((invoice) =>
      invoice.invoicenumber?.toLowerCase().includes(invoiceNumber)
    );
  }

  if (date) {
    const selectedMonth = date.getMonth();
    const selectedYear = date.getFullYear();
    filtered = filtered.filter((invoice) => {
      const invoiceDate = new Date(invoice.date);
      return (
        invoiceDate.getMonth() === selectedMonth &&
        invoiceDate.getFullYear() === selectedYear
      );
    });
  }

  if (status && status !== "all") {
    filtered = filtered.filter((invoice) => invoice.status === status);
  }

  if (labname) {
    filtered = filtered.filter((invoice) => invoice.labs?.labname === labname); // Filter by labname
  }

  setFilteredInvoices(filtered);
};

    return (
      <div>
   
          <div className="col-12 mx-xl-auto">
          {/* <div className="form-group position-relative" style={{ width: '300px' }}> 
    <input
      type="search"
      className="form-control pl-4"
      placeholder="Search by company name or company code"
      value={searchTerm}
      onChange={handleSearch}
      style={{ paddingLeft: "2rem", width: "100%" }} 
    />
    <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
      <FontAwesomeIcon icon={faSearch} />
    </span>
  </div> */}
  
            
            <br />
            <div className="card card-raised ">
              <div className="card-body ">
                <div className="card-title form-group d-flex align-items-center justify-content-between">
                  <span>Outgoing Lab Invoices</span>
                  <div>
  
                    <button className="btn btn-primary" onClick={handleCreateInvoices}>
                      ADD
                    </button>
                  </div>
                </div>
                <br />
              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="invoiceNumberFilter" className="form-label">
                    Search by Invoice Number:
                  </label>
                  <input
                    type="search"
                    className="form-control"
                    id="invoiceNumberFilter"
                    placeholder="Search by Invoice Number"
                    value={invoiceNumberFilter}
                    onChange={handleSearchInvoiceNumber}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="monthYearFilter" className="form-label">
                    Filter by Month and Year:
                  </label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    className="form-select"
                    placeholderText="Select Month and Year"
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="statusFilter" className="form-label">
                    Filter by Status:
                  </label>
                  <select
                    id="statusFilter"
                    className="form-select"
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                  >
                    <option value="all">ALL</option>
                    <option value="SUBMITTED">SUBMITTED</option>
                    <option value="PAID">PAID</option>
                    <option value="REJECTED">REJECTED</option>
                  </select>
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="labFilter" className="form-label">
                    Filter by Lab:
                  </label>
                  <select
                    id="labFilter"
                    className="form-select"
                    value={selectedLab || "all"}
                    onChange={handleLabFilterChange}
                  >
                    <option value="all">All Labs</option>
                    {labs.map((lab) => (
                      <option key={lab.id} value={lab.labname}>
                        {lab.labname}
                      </option>
                    ))}
                  </select>
                </div>
  
              </div>
  
                <br />
                <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                      <th>Lab Name</th>
                      <th>Date</th>
                      <th>Period</th>
                      <th>Invoice Number</th>
                      <th>Gross Amount</th>
                      <th>Tax</th>
                      <th>Net Pay</th>
                      <th>File</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInvoices.length === 0 ? (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No Data Available
                        </td>
                      </tr>
                    ) : (
                      filteredInvoices.map((accd) => (
                        <tr key={accd.id}>
                          <td>{accd.labs?.labname}</td>
                          <td>{accd.date} </td>
                          <td>{accd.startdate} to {accd.enddate}</td>
                          <td>{accd.invoicenumber}</td>
                          <td>{accd.grossamount}</td>
                          <td>{accd.tax}</td>
                          <td>{accd.netpay}</td>
                          <td>
                      {accd.file ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => handleViewPdf(accd.id)}
                          >
                            View
                          </button>
                        ) : (
                          "No File Added"
                        )}
                        </td>
                        <td>{accd.status}</td>

                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
  
                <div>
                  {isModalOpen && (
                    <AddInvoicesByCorporate isOpen={isModalOpen} onRequestClose={handleCloseModal}/>
                  )}
                 
                </div>
              </div>
            </div>
          </div>
          {loader && <div className="loading"></div>}
        </div>
 
    );
  }

export default ViewOutgoingInvoicesByLabCorporate;
