import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../api/baseurl";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";

const AddInvoicesByAdmin = ({ isOpen, onRequestClose }) => {
  const navigate = useNavigate();
  
  const date = new Date();
  date.setDate(date.getDate());
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();
  let currentDate = `${year}-${month}-${day}`;      

  const url = "/api/companyinvoices/create";

  const [invoiceType, setInvoiceType] = useState(""); // To store selected invoice type (Lab or Company)
  const [labs, setLabs] = useState([]);
  const [companies, setCompanies] = useState([]); // Assuming you will get companies as well
  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(false);

  const [invoices, setInvoices] = useState({
    date: currentDate,
    startdate: "",
    enddate: "",
    labcode: "",
    companycode: "", // Added companycode for Company invoice
    grossamount: "",
    tax: "",
    netpay: "",
    invoicenumber: "",
    file: null,
    fileContentType: "",
    fileSize: "",
    createdBy:""
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;

        const resLabs = await axios.get(
          "/api/labdetails/getLabs",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setLabs(resLabs.data);

        const resCompanies = await axios.get("/api/companydetails/getCompanies", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCompanies(resCompanies.data);

        const taxRes = await axios.get("/api/tax", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTaxes(taxRes.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleError(error);
      }
    };
    fetchData();
  }, []);

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    const userRole = JSON.parse(localStorage.getItem("data")).role; // Assuming the role is saved in the 'data' object

  // Set createdBy based on the role
  let createdBy = "";
  if (userRole === "ROLE_ADMIN") {
    createdBy = "Admin"; // Set as Admin if the role is admin
  } else if (userRole === "ROLE_LAB" || userRole === "ROLE_LAB_CORPORATE") {
    createdBy = "Lab"; // Set as Company if the role is employer
  }else if (userRole === "ROLE_SUPER") {
    createdBy = "Super"; // Set as Company if the role is employer
  }

    if (
      invoices.date &&
      invoices.startdate &&
      invoices.enddate &&
      invoices.grossamount &&
      invoices.netpay &&
      invoices.invoicenumber
    ) {
      axios
        .post(
          url,
          {
            date: invoices.date,
            startdate: invoices.startdate,
            enddate: invoices.enddate,
            labcode: invoices.labcode,
            companycode: invoices.companycode, // Include companycode if it's a company invoice
            grossamount: invoices.grossamount,
            tax: invoices.tax,
            netpay: invoices.netpay,
            invoicenumber: invoices.invoicenumber,
            file: invoices.file,
            fileContentType: invoices.fileContentType,
            fileSize: invoices.fileSize,
            createdBy:createdBy
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setLoading(false);
          onRequestClose();
          setInvoices("");
          toast("Invoice Added Successfully");
        })
        .catch((error) => {
          setLoading(false);
          handleError(error);
        });
    } else {
      setLoading(false);
      toast.error("Please fill out all the required fields.");
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInvoices((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    if (id === "grossamount" || id === "tax") {
      calculateNetPay({ ...invoices, [id]: value });
    }
  };

  const calculateNetPay = ({ grossamount, tax }) => {
    const grossAmountValue = parseFloat(grossamount) || 0;
    const taxValue = parseFloat(tax) || 0;
    const netPay = grossAmountValue + (grossAmountValue * taxValue) / 100;
    setInvoices((prevState) => ({
      ...prevState,
      netpay: netPay.toFixed(2),
    }));
  };

  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handlePdfFileChange = async (e) => {
    const file = e.target.files[0];
    const convertedPdf = await getBase64FromFile(file);
    const pdfObject = {
      ...invoices,

      file: convertedPdf.content,
      fileSize: file.size,
      fileContentType: convertedPdf.metadata,
    };

    setInvoices(pdfObject);
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Outgoing Invoices</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-2">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="invoiceType">
                        Invoice For <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-select"
                        id="invoiceType"
                        value={invoiceType}
                        onChange={(e) => {
                          setInvoiceType(e.target.value);
                          setInvoices({ ...invoices, labcode: "", companycode: "" }); // Reset the previous selection
                        }}
                        required
                      >
                        <option value="">Select Invoice Type</option>
                        <option value="Lab">Lab</option>
                        <option value="Company">Company</option>
                      </select>
                    </div>
                  </div>
                

                {/* Fields for Lab Invoice */}
                {invoiceType === "Lab" && (
                  <>
             
                      <div className="col-md-6">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="labcode">
                            Lab <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-select"
                            id="labcode"
                            value={invoices.labcode}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Lab</option>
                            {labs.map((lab) => (
                              <option key={lab.id} value={lab.labcode}>
                                {lab.labname} - {lab.labcode}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
              
                  </>
                )}

                {/* Fields for Company Invoice */}
                {invoiceType === "Company" && (
                  <>
                
                      <div className="col-md-6">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="companycode">
                            Company <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-select"
                            id="companycode"
                            value={invoices.companycode}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Select Company</option>
                            {companies.map((company) => (
                              <option key={company.id} value={company.companycode}>
                                {company.companyname} - {company.companycode}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                 
                  </>
                )}
                </div>

                {/* Additional Fields */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="startdate">
                        Start Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="startdate"
                        value={invoices.startdate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="enddate">
                        End Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="enddate"
                        value={invoices.enddate}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="grossamount">
                        Gross Amount <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="grossamount"
                        value={invoices.grossamount}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                 <div className="col-md-6">
                <div className="form-floating-sm mb-2">
                    <label htmlFor="tax">
                      Tax (%) <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="tax"
                      value={invoices.tax}
                      onChange={(e) => handleChange(e)}
                      required
                    >
                      <option value="">Select Tax</option>
                      {taxes.map((t) => (
                        <option key={t.id} value={t.tax}>
                          {t.tax}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="netpay">Net Pay</label>
                      <input
                        type="number"
                        className="form-control"
                        id="netpay"
                        value={invoices.netpay}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="invoicenumber">
                        Invoice Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="invoicenumber"
                        value={invoices.invoicenumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="file">Invoice PDF</label>
                      <input
                        type="file"
                        className="form-control"
                        id="file"
                        onChange={handlePdfFileChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mt-2">
                  <Button type="submit" className="btn btn-primary me-4">
                    Submit
                  </Button>

                  <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddInvoicesByAdmin;
