import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/baseurl";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const AppointmentReschedule = ({ isOpen, onRequestClose, appointmentId }) => {
  const [newDate, setNewDate] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDateChange = (e) => {
    setNewDate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newDate) {
      toast.warn("Please select a date");
      return;
    }
 
    const selectedDate = new Date(newDate);
    const currentDate = new Date();
    
    if (selectedDate <= currentDate) {
      toast.warn("Please select a date after today.");
      return;
    }
    
    if (selectedDate.getDay() === 0) {
      toast.warn("Please select a weekday other than Sunday.");
      return;
    }
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      await axios.post(
        "/api/employeeappointment/accept-or-reschedule",
        {
          id: `${appointmentId}`,
          appointmentstatus: "APPOINTMENT RESCHEDULED",
          date:newDate
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Employee Appointment Rescheduled successfully.");
      onRequestClose();
      setLoading(false);
    } catch (error) {
    setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Appointment Reschedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="newDate" className="me-1">Select New Date:</label>
                    <input
                      className="form-control"
                      id="newDate"
                      type="date"
                      placeholder="Select new date"
                      value={newDate}
                      onChange={handleDateChange}
                      required
                    />
                  </div>
                </div>
                <br />
                <input
                  type="submit"
                  value="Save"
                  className="btn btn-primary"
                  disabled={loading}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button variant="secondary" onClick={onRequestClose}>
                  Close
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default AppointmentReschedule;
