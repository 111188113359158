import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdDeleteForever } from "react-icons/md";
import { BiEditAlt } from "react-icons/bi";
import CreatePackages from "./addpackages";
import LinkPackagesAndTests from "./linkPackagesAndTests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function Packages() {
  const [packages, setPackages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isMapPackageAndTest, setIsMapPackageAndTest] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const trans = await axios.get(`/api/package/getAllpackagesByRole`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const packageData = trans.data;
      await fetchTestNames(packageData);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const fetchTestNames = async (packages) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const packageTestNames = await Promise.all(
      packages.map(async (pkg) => {
        const response = await axios.get(`/api/packagetestmapping/packageid=${pkg.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const testNames = response.data.map((item) => item.lab?.testname);
        return {
          ...pkg,
          testNames: Array.isArray(testNames) ? testNames : [],
        };
      })
    );
    setPackages(packageTestNames);
    setLoader(false);
  };

  const getCompanies = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/companydetails/getCompanies`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCompanies(response.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleCreatePackage = () => {
    setSelectedPackage(null);
    setIsModalOpen(true);
  };

  const handleEditPackage = (pkg) => {
    setSelectedPackage(pkg);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  const handleOpenMapPopup = (packageId) => {
    setSelectedPackage(packageId);
    setIsMapPackageAndTest(true);
  };

  const handleCloseMapPopup = () => {
    setSelectedPackage(null);
    setIsMapPackageAndTest(false);
    getData();
  };

  useEffect(() => {
    getCompanies();
    getData();
  }, []);

  const handleDeletePackage = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(id);
    try {
      await axios({
        url: "/api/package",
        method: "delete",
        headers: { Authorization: `Bearer ${token}` },
        data: {
          id: `${id}`,
          orgcode: `${JSON.parse(localStorage.getItem("data")).orgcode}`,
        },
      });
      toast.success("Deleted successfully");
      setLoader(false);
      getData();
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const filteredPackages = packages.filter((pkg) => {
    const matchesCompany =
      selectedCompany === "" || (pkg.company && pkg.company.companyname === selectedCompany);
    const matchesSearchQuery = pkg.packagename.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCompany && matchesSearchQuery;
  });

  return (
    <div>
      <div>
        <Dashboard />
      </div>
      {loader && <div className="loading"></div>}
      <div>
        <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
          <div className="card card-raised">
            <div className="card-body">
              <div className="card-title form-group d-flex align-items-center justify-content-between">
                Packages
                <button className="btn btn-primary" onClick={handleCreatePackage}>
                  ADD
                </button>
              </div>

              <br />
              <div className="form-group d-flex align-items-center ">
              <div className="row col-md-6">
                <div className="col-6">
                  <label htmlFor="companyFilter">FILTER BY COMPANY:</label>
                  <select
                    id="companyFilter"
                    className="form-select me-3"
                    value={selectedCompany}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                  >
                    <option value="">All Companies</option>
                    {companies.map((comp) => (
                      <option key={comp.id} value={comp.companyname}>
                        {comp.companyname}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                <div className="form-group position-relative">
                  <label htmlFor="searchQuery"></label>
                  <input
                    id="searchQuery"
                    type="text"
                    className="form-control"
                    placeholder="Search by Package Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                   <span className="position-absolute" style={{ right: "10px", top: "70%", transform: "translateY(-50%)" }}>
    <FontAwesomeIcon icon={faSearch} />
  </span>
  </div>
                </div>
              </div>
            </div>

              <br />
              <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                    <th>Package Name</th>
                    <th>Company Name</th>
                    <th>Package Code</th>
                    <th>Descriptions</th>
                    <th>Price</th>
                    <th>Tests</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPackages.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    filteredPackages.map((pkg) => (
                      <tr key={pkg.id}>
                        <td>{pkg.packagename}</td>
                        <td>{pkg.company?.companyname}</td>
                        <td>{pkg.packageCode}</td>
                        <td>{pkg.description}</td>
                        <td>{pkg.price ? pkg.price : 0}</td>
                        <td>
                          {pkg.testNames.length>0 ? (
                          <ul>
                            {pkg.testNames?.map((testName, index) => (
                              <li key={index}>{testName}</li>
                            ))}
                          </ul>
                          ):(
                            "No Tests Added"
                          )}
                        </td>
                        <td style={{ fontSize: "17px", cursor: "pointer" }}>
                          <button
                            className="btn btn-link text-primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => handleOpenMapPopup(pkg.id)}
                          >
                            Link Tests
                          </button>
                          <button
                            onClick={() => handleEditPackage(pkg)}
                            style={{ border: "none", background: "white" }}
                          >
                            <BiEditAlt />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <div>
                {isModalOpen && (
                  <CreatePackages
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                    selectedPackage={selectedPackage} // Pass the selected package data for editing
                  />
                )}
                {isMapPackageAndTest && (
                  <LinkPackagesAndTests
                    isOpen={isMapPackageAndTest}
                    onRequestClose={handleCloseMapPopup}
                    packageId={selectedPackage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default Packages;
