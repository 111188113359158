import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable"; 
import tmplLogo from "../images/tmplLogo.png";

function AppointmentReports() {
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(false); 
  const [fromDate, setFromDate] = useState(""); 
  const [toDate, setToDate] = useState(""); 
  const [status, setStatus] = useState(""); 
  const [appNotTakenData, setAppNotTakenData] = useState([]);

  const navigate = useNavigate();
  const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;

  // Function to fetch data based on selected fromDate, toDate, and status
const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    // Prepare request options with Authorization header
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
  
    try {
      setLoader(true); // Show loader while fetching data
  
      let response;
  
      if (status) {
        // If status is selected, call the API specific for status
        const requestBody = {
          companyCode: orgcode,
          fromDate: fromDate,
          toDate: toDate,
          appointmentStatus: status
        };
  
        response = await axios.post('/api/getAppointmentsByStatus', requestBody, options);
      } else {
        // If no status is selected, call the general API
        const url = `/api/getAppointments/companycode=${orgcode}/fromdate=${fromDate}/todate=${toDate}`;
        response = await axios.get(url, options);
      }
  
      // Set the appointment details from the response
      setAppointmentDetails(response.data.empAppointments);
      setAppNotTakenData([]);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false); // Hide loader after fetching data
    }
  };

  const getAppNotTakenEmpData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    // Prepare request options with Authorization header
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
  
    try {
      setLoader(true); 
        const url = `/api/employeedetails/getAppointmentsNotTakenEmployees/companycode=${orgcode}`;
        let response = await axios.get(url, options);
      setAppNotTakenData(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false); // Hide loader after fetching data
    }
  };
  
  
  // Error handling function
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    } else {
      toast.error("An error occurred.");
    }
  }

  // Function to handle "Generate" button click
  const handleGenerateReport = () => {
    // Only fetch data if both fromDate and toDate are selected
    if (fromDate && toDate) {
      getData();
    } else {
      toast.error("Please select both From Date and To Date.");
    }
  };

  const handleAppNotTakenReport = () => {
      getAppNotTakenEmpData();
  };

// Function to download report as a PDF
const handleDownloadReport = () => {
    if(appNotTakenData.length>0){
        if (appNotTakenData.length === 0) {
            toast.error("No data available to generate PDF.");
            return;
          }
        
          const doc = new jsPDF('l', 'mm', 'a4');
        
          const logoWidth = 40;
          const logoHeight = 10;
          const titleFontSize = 16;
          const titleMargin = 10; // Margin between logo and title
        
          // Add Logo to the PDF
          doc.addImage(tmplLogo, 'PNG', 10, 10, logoWidth, logoHeight); // Position the logo
        
          // Title
          doc.setFontSize(titleFontSize); // Title font size
          const pageWidth = doc.internal.pageSize.width;
          const titleText = "Appointment Not Taken Employees Report";
          const titleWidth = doc.getStringUnitWidth(titleText) * titleFontSize / doc.internal.scaleFactor; // Calculate title width
          const titleX = (pageWidth - logoWidth - titleMargin - titleWidth) / 2 + logoWidth + titleMargin; // Center title after the logo
        
          doc.text(titleText, titleX, 20); // Adjust Y position as needed
        
        
          const tableColumn = [
            "Sl No.",
            "Name",
            "Employee ID",
            "Email",
            "Phone Number",
          ];
          const tableRows = [];
        
          appNotTakenData.forEach((appointment, index) => {
            const appointmentData = [
              index + 1,
              `${appointment.name} ${appointment.lastname}`,
              appointment.empid,
              appointment.email,
              appointment.phoneno,
            ];
            tableRows.push(appointmentData);
          });
        
          doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30, // Adjust startY position based on whether status is included
            margin: { top: 50, bottom: 30 }, // Increase bottom margin to fit the note
            pageBreak: 'auto', // Automatic page breaking if needed
            showHead: 'firstPage', // Show header only on the first page
            theme: 'striped' // Optional: Apply striped theme to the table
          });
        
          // Footer text
          const footerText = "This is a system-generated report and does not need a signature.";
          const pageHeight = doc.internal.pageSize.height; // Get the height of the page
          const marginBottom = 20; // Margin from the bottom of the page
        
          // Set font size and color for the footer
          doc.setFontSize(10); // Font size for footer
          doc.setTextColor(128, 128, 128); // Grey color
        
          doc.text(footerText, 14, pageHeight - marginBottom); // Position the text near the bottom edge
        
          doc.save(`Appointment_Report.pdf`);
    }else{
    if (appointmentDetails.length === 0) {
      toast.error("No data available to generate PDF.");
      return;
    }
  
    const doc = new jsPDF('l', 'mm', 'a4');
  
    const logoWidth = 40;
    const logoHeight = 10;
    const titleFontSize = 16;
    const titleMargin = 10; // Margin between logo and title
  
    // Add Logo to the PDF
    doc.addImage(tmplLogo, 'PNG', 10, 10, logoWidth, logoHeight); // Position the logo
  
    // Title
    doc.setFontSize(titleFontSize); // Title font size
    const pageWidth = doc.internal.pageSize.width;
    const titleText = "Employees Appointment Report";
    const titleWidth = doc.getStringUnitWidth(titleText) * titleFontSize / doc.internal.scaleFactor; // Calculate title width
    const titleX = (pageWidth - logoWidth - titleMargin - titleWidth) / 2 + logoWidth + titleMargin; // Center title after the logo
  
    doc.text(titleText, titleX, 20); // Adjust Y position as needed
  
    // Date texts
    doc.setFontSize(12); // Date font size
    doc.text(`From Date: ${fromDate}`, 14, 30);
    doc.text(`To Date: ${toDate}`, 14, 40);
  
    // Conditionally add status text
    if (status) {
      doc.text(`Appointment Status: ${status}`, 14, 50); // Adjust Y position as needed
    }
  
    const tableColumn = [
      "Sl No.",
      "Name",
      "Employee ID",
      "Email",
      "Phone Number",
      "Lab Name",
      "Lab Code",
      "Appointment Status",
    ];
    const tableRows = [];
  
    appointmentDetails.forEach((appointment, index) => {
      const appointmentData = [
        index + 1,
        `${appointment.emplo?.name} ${appointment.emplo?.lastname}`,
        appointment.emplo?.empid,
        appointment.emplo?.email,
        appointment.emplo?.phoneno,
        appointment.labs?.labname,
        appointment.labs?.labcode,
        appointment.appStatus?.appointmentStatus,
      ];
      tableRows.push(appointmentData);
    });
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: status ? 60 : 50, // Adjust startY position based on whether status is included
      margin: { top: 50, bottom: 30 }, // Increase bottom margin to fit the note
      pageBreak: 'auto', // Automatic page breaking if needed
      showHead: 'firstPage', // Show header only on the first page
      theme: 'striped' // Optional: Apply striped theme to the table
    });
  
    // Footer text
    const footerText = "This is a system-generated report and does not need a signature.";
    const pageHeight = doc.internal.pageSize.height; // Get the height of the page
    const marginBottom = 20; // Margin from the bottom of the page
  
    // Set font size and color for the footer
    doc.setFontSize(10); // Font size for footer
    doc.setTextColor(128, 128, 128); // Grey color
  
    doc.text(footerText, 14, pageHeight - marginBottom); // Position the text near the bottom edge
  
    doc.save(`Appointment_Report_${fromDate}_to_${toDate}.pdf`);
}
  };

  
  return (
    <div>
      <Dashboard />
      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Appointments Reports
            </div>
            <br />
            <div className="row">
              <div className="col-md-4">
                <label>From Date:</label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label>To Date:</label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label>Appointment Status :</label>
                <select
                  className="form-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">ALL</option>
                  <option value="APPOINTMENT CONFIRMATION PENDING WITH LAB">
                    APPOINTMENT CONFIRMATION PENDING 
                  </option>
                  <option value="APPOINTMENT CONFIRMED">APPOINTMENT CONFIRMED</option>
                  <option value="APPOINTMENT COMPLETED">APPOINTMENT COMPLETED</option>
                  <option value="APPOINTMENT CANCELLED">APPOINTMENT CANCELLED</option>
                </select>
              </div>
            </div>
  
            <br />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <button className="btn btn-secondary" onClick={handleAppNotTakenReport}>
                Appointment Not Taken 
                </button>
              <div>
                <button className="btn btn-primary me-4" onClick={handleGenerateReport}>
                  Generate Report
                </button>
                <button className="btn btn-primary" onClick={handleDownloadReport}>
                  Download Report
                </button>
              </div>
            </div>
  
            <br />
            {appNotTakenData.length === 0 && appointmentDetails.length>0 &&(
                    <>
                    <h5>Appointment Taken Employees :</h5>
                    <br/>
            <table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>Name</th>
                  <th>Employee Id</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Lab Name</th>
                  <th>Lab Code</th>
                  <th>Appointment Status</th>
                </tr>
              </thead>
              <tbody>
                {/* Render Regular Appointment Details */}
                {appointmentDetails.length === 0 && appNotTakenData.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  <>
                    {appointmentDetails.map((appointment) => (
                      <tr key={appointment.id}>
                        <td>
                          {appointment.emplo?.name} {appointment.emplo?.lastname}
                        </td>
                        <td>{appointment.emplo?.empid}</td>
                        <td>{appointment.emplo?.email}</td>
                        <td>{appointment.emplo?.phoneno}</td>
                        <td>{appointment.labs?.labname}</td>
                        <td>{appointment.labs?.labcode}</td>
                        <td>{appointment.appStatus?.appointmentStatus}</td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            </>
            )}
{appNotTakenData.length > 0 && (
    <>
    <h5>Appointment Not Taken Employees :</h5>
    <br/>
<table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>Name</th>
                  <th>Employee Id</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                {/* Render Regular Appointment Details */}
                {appNotTakenData.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
       
                    appNotTakenData.map((employee) => (
                      <tr key={employee.id}>
                        <td>
                          {employee.name} {employee.lastname}
                        </td>
                        <td>{employee.empid}</td>
                        <td>{employee.email}</td>
                        <td>{employee.phoneno}</td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
            </>
            )}
          </div>
        </div>
      </div>
      {loader && <div className="loading"></div>}
    </div>
  );
  
}

export default AppointmentReports;
