import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "../api/baseurl"; // Adjust the import path as needed
import { Modal, Button, Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";

const CreateFinancialYear = ({ isOpen, onRequestClose }) => {
  const [fromMonth, setFromMonth] = useState("");
  const [toMonth, setToMonth] = useState("");
  const [loading, setLoading] = useState(false);

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const navigate = useNavigate();
  const [financialYearId, setFinancialYearId] = useState(null);

  const getFinancialYearData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    try {
      setLoading(true);
      const response = await axios.get("/api/year-settings", {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Check if the response data is an array and has at least one item
      if (Array.isArray(response.data) && response.data.length > 0) {
        const { id, fromDate, toDate } = response.data[0]; // Assuming the first item contains the needed data
  
        if (id) {
          setFinancialYearId(id); // Save the ID for update operation
          setFromMonth(fromDate);
          setToMonth(toDate);
        } else {
          console.log("No financial year data found");
        }
      } else {
        console.log("Unexpected response format");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  

  // Fetch the financial year data when the modal opens
  useEffect(() => {
    if (isOpen) {
      getFinancialYearData();
    }
  }, [isOpen]);

  // Handle start month change and automatically set end month
  const handleStartMonthChange = (e) => {
    const selectedMonthIndex = parseInt(e.target.value) - 1;
    const selectedMonth = months[selectedMonthIndex];

    setFromMonth(selectedMonth);

    // Calculate the end month, exactly 1 year later
    const calculatedEndMonthIndex = selectedMonthIndex === 0 ? 11 : selectedMonthIndex - 1;
    setToMonth(months[calculatedEndMonthIndex]);
  };

  // Submit updated or new financial year data
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem("data")).token;
      
      // Check if an ID exists (i.e., data already exists, so we need to update)
      if (financialYearId) {
        // Call update API (PUT request) if data exists
        await axios.post(`/api/year-settings/update`, {
          id:financialYearId,
          fromDate: fromMonth,
          toDate: toMonth,
        }, { headers: { Authorization: `Bearer ${token}` } });
  
        toast.success("Financial year updated successfully");
        setLoading(false);
      } else {
        // Call create API (POST request) if no existing data
        await axios.post("/api/year-settings/create", {
          fromDate: fromMonth,
          toDate: toMonth,
        }, { headers: { Authorization: `Bearer ${token}` } });
  
        toast.success("Financial year created successfully");
        setLoading(false);
      }
  
      onRequestClose(); // Close modal after successful submission
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };
  
  // Error handling function
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    } else {
      toast.error(error.message || "An error occurred.");
    }
  }

  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{financialYearId ? "Update Financial Year" : "Create Financial Year"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <div className="loading"></div>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="fromDate">
            <Form.Label>From Date</Form.Label>
            <Form.Control
              as="select"
              value={months.indexOf(fromMonth) + 1} // Match dropdown value with the month
              onChange={handleStartMonthChange}
            >
              <option value="">Select From Date</option>
              {months.map((month, index) => (
                <option key={index} value={index + 1}>{month}</option>
              ))}
            </Form.Control>
          </Form.Group>

          {toMonth && (
            <Form.Group controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <Form.Control type="text" value={toMonth} readOnly />
            </Form.Group>
          )}

          <Button type="submit" variant="primary" className="mt-3 me-3">
            Save
          </Button>
          <Button variant="secondary" onClick={onRequestClose} className="mt-3">
            Close
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateFinancialYear;
