import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "@material/mwc-tab-bar/mwc-tab-bar.js";
import "@material/mwc-tab/mwc-tab.js";
import "@material/mwc-textfield/mwc-textfield.js";
import AdminDashboard from "./admindashboard";
import CancelAppointment from "./cancelAppointment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { format, parse, startOfMonth, endOfDay } from 'date-fns';

// Function to format date to 'dd/MMM/yyyy HH:mm:ss'
const formatDateForDisplay = (date) => format(date, 'dd/MMM/yyyy HH:mm:ss');

// Function to parse date from 'dd/MMM/yyyy HH:mm:ss'
const parseDateFromDisplay = (dateStr) => parse(dateStr, 'dd/MMM/yyyy HH:mm:ss', new Date());

function Financials() {
  const [loading, setLoading] = useState(false);
  const [userPayments, setUserPayments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("ALL");
  const [fromDate, setFromDate] = useState(formatDateForDisplay(startOfMonth(new Date())));
  const [toDate, setToDate] = useState(formatDateForDisplay(new Date()));

  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;

      // Convert fromDate and toDate to the desired format
      const formattedFromDate = formatDateForDisplay(parseDateFromDisplay(fromDate));
      const formattedToDate = formatDateForDisplay(parseDateFromDisplay(toDate));

      const response = await axios.post(
        `/api/user/userpayment/payments`,
        {
          fromDate: formattedFromDate,
          toDate: formattedToDate,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setUserPayments(response.data);
      setFilteredData(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [fromDate, toDate]); // Update data when fromDate or toDate changes

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const filteredPayments = filter === "ALL"
    ? filteredData
    : filteredData.filter(payments => payments.paymentStatus === filter);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = userPayments.filter(
      (payment) =>
        payment.company?.companyname.toLowerCase().includes(term) || payment.employee?.name.toLowerCase().includes(term)
    );
    setFilteredData(filtered);
  };

  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const handleCancelClick = async (payment) => {
    setSelectedPayment(payment);
    setIsCancelPopupOpen(true);
  };

  const handleCloseCancelPopup = () => {
    setIsCancelPopupOpen(false);
    getData();
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>

      {loading && <div className="loading"></div>}

      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between mb-4">
              <span>Payment Details</span>
            </div>

            <div className="d-flex align-items-center mb-3 flex-wrap">
              <div className="position-relative me-3 flex-grow-1">
                <input
                  type="search"
                  className="form-control pl-4"
                  placeholder="Search by company name and user name"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ paddingLeft: "2rem", width: "60%" }} // Ensure the input takes the full width of the parent div
                />
                <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <div className="me-3">
                <label>Filter By Status</label>
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="form-select"
                >
                  <option value="ALL">ALL</option>
                  <option value="SUCCESS">PAYMENT SUCCESS</option>
                  <option value="PAYMENT PROCESSED">PAYMENT PROCESSED</option>
                  <option value="REFUND PROCESSED">REFUND PROCESSED</option>
                </select>
              </div>
              <div className="me-3">
                <label>From Date</label>
                <input
                  type="date"
                  value={format(parse(fromDate, 'dd/MMM/yyyy HH:mm:ss', new Date()), 'yyyy-MM-dd')}
                  onChange={(e) => setFromDate(formatDateForDisplay(new Date(e.target.value)))}
                  className="form-control"
                />
              </div>
              <div>
                <label>To Date</label>
                <input
                  type="date"
                  value={format(parse(toDate, 'dd/MMM/yyyy HH:mm:ss', new Date()), 'yyyy-MM-dd')}
                  onChange={(e) => setToDate(formatDateForDisplay(new Date(e.target.value)))}
                  className="form-control"
                />
              </div>
            </div>

            <table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>Company Name</th>
                  <th>Employee Name</th>
                  <th>Date</th>
                  <th>Payment Id</th>
                  <th>Amount</th>
                  <th>Payment Status</th>
                </tr>
              </thead>
              <tbody>
              {filteredPayments.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  filteredPayments.map((payment, index) => {
                    return (
                      <tr key={index}>
                        <td>{payment.company?.companyname}</td>
                        <td>
                          <div>
                            {payment.employee?.name} &nbsp;
                            {payment.employee?.lastname}
                          </div>
                          <div style={{ fontSize: '14px', color: '#999' }}>
                          {payment.memberName}
                            </div>
                        </td>
                        <td>{payment.paymentdate}</td>
                        <td>{payment.razorpay_payment_id}</td>
                        <td>{payment.paymentAmount} ₹</td>
                        <td>
                          <div>{payment.paymentStatus}</div>
                          {payment.paymentStatus === "PAYMENT PROCESSED" && (
                            <div
                              style={{
                                display: "inline-block",
                                padding: "0.25em 0.5em",
                                borderRadius: "10px",
                                backgroundColor: "red",
                                color: "white",
                                border: "1px solid #c3e6cb",
                                marginTop: "0.5em",
                                cursor: "pointer",
                              }}
                              title="Cancel Appointment"
                              onClick={() => handleCancelClick(payment)}
                            >
                              REFUND
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            {isCancelPopupOpen && (
              <CancelAppointment
              isOpen={isCancelPopupOpen}
                onRequestClose={handleCloseCancelPopup}
                payment={selectedPayment}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Financials;
