import React, { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import { FaMinusCircle, FaPlus, FaPlusCircle, FaTrash, FaUser } from "react-icons/fa";

const CreateLabDetails = ({ isOpen, onRequestClose ,regulated}) => {
  const navigate = useNavigate();

  console.log("regulated", regulated);

  const url = "/api/CreateCompanyandLab";
  const [labDetails, setLabDetails] = useState({
    username: "",
    role: "ROLE_LAB",
    organization: {
      username: "",
      labname: "",
      labcode: "",
      contact: "",
      description: "",
      pan: "",
      gstin: "",
      tan: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      pincode: "",
      state: "",
      createddate: "",
      latitude: "",
      longitude: "",
      sendNotification: false,
      regulated: false,
      labCoordinators: [
        {
          coOrdinatorName: "",
          coOrdinatorEmail: "",
          coOrdinatorMobileno: "",
        },
      ],
    },
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem("data")).token;

    try {
      // Check if email already exists
      const emailCheckResponse = await axios.get(`/api/accounts/getByEmail=${labDetails.username}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (emailCheckResponse.data) {
        toast.error("Email already exists.");
        setLoading(false);
        return;
      }
    } catch (error) {
      handleError(error);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(url, {
        username: labDetails.username,
        role: "ROLE_LAB",
        labDetails: {
          username: labDetails.username,
          labname: labDetails.labname,
          labcode: labDetails.labcode,
          contact: labDetails.contact,
          description: labDetails.description,
          pan: labDetails.pan,
          gstin: labDetails.gstin,
          tan: labDetails.tan,
          addressLine1: labDetails.addressLine1,
          addressLine2: labDetails.addressLine2,
          city: labDetails.city,
          pincode: labDetails.pincode,
          state: labDetails.state,
          createddate: labDetails.createddate,
          latitude: labDetails.latitude,
          longitude: labDetails.longitude,
          sendNotification: labDetails.sendNotification,
          regulated: regulated,
          labCoordinators: labDetails.organization.labCoordinators,
        },
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setLoading(false);
      onRequestClose();
      setLabDetails({ ...labDetails, username: "", labname: "", labcode: "", contact: "", description: "" }); // reset form fields
      toast.success("Lab account created successfully and password sent to their email.");

    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  function rhandle(e) {
    const { id, value } = e.target;
    setLabDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  const handleSendNotificationChange = (e) => {
    const { checked } = e.target;
    setLabDetails((prevState) => ({
      ...prevState,
      sendNotification: checked,
    }));
  };

  const handleAddCoordinator = () => {
    setLabDetails((prevState) => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        labCoordinators: [
          ...prevState.organization.labCoordinators,
          { coOrdinatorName: "", coOrdinatorEmail: "", coOrdinatorMobileno: "" },
        ],
      },
    }));
  };

  const handleRemoveCoordinator = (index) => {
    const newCoordinators = labDetails.organization.labCoordinators.filter((_, i) => i !== index);
    setLabDetails((prevState) => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        labCoordinators: newCoordinators,
      },
    }));
  };

  const handleCoordinatorChange = (e, index) => {
    const { id, value } = e.target;
    const updatedCoordinators = labDetails.organization.labCoordinators.map((coordinator, i) => {
      if (i === index) {
        return { ...coordinator, [id]: value };
      }
      return coordinator;
    });

    setLabDetails((prevState) => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        labCoordinators: updatedCoordinators,
      },
    }));
  };
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered size="lg">
    <Modal.Header closeButton>
    <Modal.Title>Create Lab Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    {loading && <div className="loading"></div>} 
    <div className="col-xl-10 mx-xl-auto">
          <div className="card card-raised mb-3">
            <div className="card-body p-4 ">
            <form onSubmit={handleSubmit}>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="labname">Lab Name <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="labname"
                      type="text"
                      placeholder="Lab Name"
                      value={labDetails.labname}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                   </div>
                  </div>
               
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="labcode">Lab Code <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="labcode"
                      type="text"
                      placeholder="Lab Code"
                      value={labDetails.labcode}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
               </div>
               </div>
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="username">Email <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="username"
                      type="email"
                      placeholder="Email"
                      value={labDetails.username}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="contact">Contact Number <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="contact"
                      type="text"
                      placeholder="contact number"
                      value={labDetails.contact}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="gstin">GSTIN 
                      {/* <span style={{color:"red"}}>* </span> */}
                      </label>
                    <input
                      className="form-control"
                      id="gstin"
                      type="text"
                      placeholder="GSTIN"
                      value={labDetails.gstin}
                      onChange={(e) => rhandle(e)}
                      // required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pan">PAN </label>
                    <input
                      className="form-control"
                      id="pan"
                      type="text"
                      placeholder="PAN"
                      value={labDetails.pan}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="tan">TAN</label>
                    <input
                      className="form-control"
                      id="tan"
                      type="text"
                      placeholder="TAN"
                      value={labDetails.tan}
                      onChange={(e) => rhandle(e)}
                    />
                    
                  </div>
                </div>
                </div>


                <br/>
                <div><h5>Lab Address:</h5></div>
             
                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="addressline1">Address Line1 <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="addressLine1"
                      type="text"
                      placeholder="Address"
                      value={labDetails.addressLine1}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="addressline2">Address Line2</label>
                    <input
                      className="form-control"
                      id="addressLine2"
                      type="text"
                      placeholder="Address"
                      value={labDetails.addressLine2}
                      onChange={(e) => rhandle(e)}
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="latitude">Latitude
                      {/* <span style={{color:"red"}}>* </span> */}
                      </label>
                    <input
                      className="form-control"
                      id="latitude"
                      type="text"
                      placeholder="Latitude"
                      value={labDetails.latitude}
                      onChange={(e) => rhandle(e)}
                      // required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="longitude">Longitude 
                      {/* <span style={{color:"red"}}>* </span> */}
                    </label>
                    <input
                      className="form-control"
                      id="longitude"
                      type="text"
                      placeholder="Longitude"
                      value={labDetails.longitude}
                      onChange={(e) => rhandle(e)}
                      // required
                    />
                  </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="city">City <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="city"
                      type="text"
                      placeholder="City"
                      value={labDetails.city}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="pincode">Pincode <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="pincode"
                      type="text"
                      placeholder="Pincode"
                      value={labDetails.pincode}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="state">State <span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="state"
                      type="text"
                      placeholder="State"
                      value={labDetails.state}
                      onChange={(e) => rhandle(e)}
                      required
                    />
                  </div>
                </div>
                </div>

                <br/>
<div>
<div className="d-flex justify-content-between align-items-center">
  <h5 className="d-flex align-items-center">
    <FaUser className="me-2" /> {/* Icon for Lab Coordinators */}
    Lab Co-ordinators:
  </h5>

  {/* Add Coordinator button with Plus icon */}
  <span onClick={handleAddCoordinator}>
    <FaPlusCircle /> {/* Icon for adding coordinator */}
  </span>
</div>
            {labDetails.organization.labCoordinators.map((coordinator, index) => (
              <div key={index} className="row">
                 <span variant="danger" className="align-items-end" onClick={() => handleRemoveCoordinator(index)}>
            <FaMinusCircle />  {/* Add trash icon */}
          </span>
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor={`coordinatorName-${index}`}>Co-ordinator Name<span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="coOrdinatorName"
                      type="text"
                      value={coordinator.coOrdinatorName}
                      placeholder="Co-ordinator Name"
                      onChange={(e) => handleCoordinatorChange(e, index)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor={`coordinatorEmail-${index}`}>Co-ordinator Email<span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="coOrdinatorEmail"
                      type="email"
                      value={coordinator.coOrdinatorEmail}
                      placeholder="Co-ordinator Email"
                      onChange={(e) => handleCoordinatorChange(e, index)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor={`coordinatorPhone-${index}`}>Co-ordinator Phone<span style={{color:"red"}}>* </span></label>
                    <input
                      className="form-control"
                      id="coOrdinatorMobileno"
                      type="text"
                      value={coordinator.coOrdinatorMobileno}
                      placeholder="Co-ordinator Mobile number"
                      onChange={(e) => handleCoordinatorChange(e, index)}
                      required
                    />
                  </div>
                </div>
               
        </div>
      ))}
      
     
          </div>

                <div className="form-check mt-3 mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sendNotification"
                    checked={labDetails.sendNotification}
                    onChange={handleSendNotificationChange}
                  />
                  <label className="form-check-label" htmlFor="sendNotification">
                    Send Notification
                  </label>
                  </div>
                
                <input
                  type="submit"
                  value="SAVE"
                  className="btn btn-primary"
                  disabled={loading}

                />
                 &nbsp;&nbsp;&nbsp;&nbsp;

                <Button variant="secondary" onClick={onRequestClose}>
               Close
               </Button>  

              </form>
            </div>
          </div>
        </div>
              </Modal.Body>
      <Modal.Footer>
       </Modal.Footer>
    </Modal>
  );
}
export default CreateLabDetails;
