import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { BiEditAlt } from "react-icons/bi";
import UpdateAdminLink from "./updateAdminLink";

function AdminLink({selectedOrgcode}) {
  const [adminLink, setAdminLink] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [loader, setLoader] = useState(true);

  const getData = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const response = await axios.get(`/api/companydetails/companycode=${selectedOrgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setAdminLink(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const navigate = useNavigate();
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleEditLocation = (adminLink) => {
    setCompanyDetails(adminLink); 
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);



  return (
    <div>
      <div className="company-profile">
        <div className="card card-raised mb-5">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
            <span>Admin Link</span>
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Admin Email</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {adminLink?.adminlink === null ? (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                
                    <tr key={adminLink?.id}>
                      <td>{adminLink?.adminEmail}</td>
                      <td style={{ fontSize: "17px", cursor: "pointer" }}>
                        <button
                          onClick={() => handleEditLocation(adminLink)}
                          style={{ border: "none", background: "white", size: "150px" }}
                        >
                          <BiEditAlt />
                        </button>
                      </td>
                    </tr>
                )}
              
              </tbody>
            </table>
            {isModalOpen && (
              <UpdateAdminLink isOpen={isModalOpen} onRequestClose={handleCloseModal} companyDetails={companyDetails} />
            )}
          </div>
        </div>
      </div>
      {loader && <div className="loading"></div>}
    </div>
  );
}

export default AdminLink;
