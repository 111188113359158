import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { useNavigate, useParams } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import UploadReport from "./uploadReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { FiInfo } from "react-icons/fi";
import TestForPackage from "./testsByPackagePopup";
import FamilyAppointmentCancel from "./familyAppointmentCancelPopup";
import AppointmentReschedule from "./appointmentReschedule";
import { Button, Modal, Pagination } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import AddLabCoordinators from "./addLabCoordinators";

function LabCoordinators({ labcode }) {
  // const {labcode} =useParams();
  console.log("l",labcode);

  const [labDetails, setLabDetails] = useState(null);
  const [coordinators, setCoordinators] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoordinator, setSelectedCoordinator] = useState(null);
  const [loader, setLoader] = useState(true);
 
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
   
      getData();

  }, []);

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      const labUrl = `/api/labdetails/labcode=${labcode}`;
      const labResponse = await axios.get(labUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setLabDetails(labResponse.data);

      const coordinatorUrl = `/api/lab-coordinators/labId=${labResponse.data.id}`;
      const coordinatorResponse = await axios.get(coordinatorUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setCoordinators(coordinatorResponse.data);
      

      // setFilteredData(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

 
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }



  const handleEditCoordinator = (coordinator) => {
    setSelectedCoordinator(coordinator); // Set the category ID to be edited
    setIsModalOpen(true);
  };

  const handleAddCoordinator = () => {
    setSelectedCoordinator(null); // Set the category ID to be edited
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCoordinator(null);
    getData();
  };

  const handleDelete = async (coordinator) => {
    setSelectedCoordinator(coordinator);
    setShowConfirmationModal(true);
};

  const handleDeleteConfirm = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
    
    try {
      setLoader(true);
        const requestBody = {
          id: selectedCoordinator.id,
        };
  
        await axios.delete(`/api/lab-coordinators`, { data: requestBody, ...options });
        
        toast.success('Co-ordinator details Deleted successfully!');
        setLoader(false);
        setShowConfirmationModal(false);
        setSelectedCoordinator(null);
        getData();
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handleBack = () => {
    navigate(-1);
   };

   const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };


  return (
    <div>
        {loading && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto">
      <div className="mb-2">
                      <button className="btn btn-primary" onClick={handleBack}>
                        Back
                      </button>
                    </div>
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>Co-ordinators Details</span>
              <button className="btn btn-primary" onClick={handleAddCoordinator}>
                  ADD
                </button>
              {/* <div className="form-group position-relative" style={{ width: '300px' }}>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by company code or phone number or Package"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ paddingLeft: "2rem", width: "100%" }}
                />
                <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div> */}
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Co-ordinator Name</th>
                  <th>Co-ordinator Email</th>
                  <th>Co-ordinator Mobile Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {coordinators.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  coordinators.map((coord) => (
                    <tr key={coord.id}>
                      <td>
                        {coord.coOrdinatorName}
                       
                      </td>
                      <td>{coord.coOrdinatorEmail}</td>
                      
                      <td>{coord.coOrdinatorMobileno}</td>
                      <td>
                        
                        <FaEdit
                          className="text-warning me-2"
                          style={{ cursor: "pointer" }}
                           onClick={() => handleEditCoordinator(coord)}
                        />
                        <FaTrash
                          className="text-danger"
                          style={{ cursor: "pointer" }}
                            onClick={() => handleDelete(coord)}
                        />
                      </td>
                     
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {/* <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div> */}
          </div>
        </div>
        {isModalOpen && (
                <AddLabCoordinators
                  isOpen={isModalOpen}
                  onRequestClose={handleCloseModal}
                  labId={labDetails?.id} 
                  coOrdinator={selectedCoordinator}
                />
              )}

<Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      Are you sure you want to delete this Co-ordinator Details?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="me-3" onClick={() => setShowConfirmationModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDeleteConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>


        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default LabCoordinators;
