import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddInvoicesByAdmin from "./addInvoicesByAdmin";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Pagination } from "react-bootstrap";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";

function LabIncomingInvoices({labcode}) {
  const [invoices, setInvoices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [labs, setLabs] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedLab, setSelectedLab] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [invoiceNumberFilter, setInvoiceNumberFilter] = useState("");
  const [labTypeFilter, setLabTypeFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  // Fetching data and search functionality
  useEffect(() => {
    if (searchTerm) {
      searchData();
    } else {
      getData(currentPage);
    }
  }, [searchTerm, currentPage]);

  const searchData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    try {
      const searchUrl = `/api/companyinvoices/labcode=${labcode}`;
      const response = await axios.get(searchUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setInvoices(response.data);
      setFilteredInvoices(response.data);
      setLoader(false);
    } catch (error) {
      handleError(error);
    }
  };

  const getData = async (pageNumber = 0) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const trans = await axios.get(`/api/companyinvoices/getInvoicesByLabcode/labcode=${labcode}`, {
        params: { page: pageNumber },
        headers: { Authorization: `Bearer ${token}` },
      });

      setInvoices(trans.data.content);
      setFilteredInvoices(trans.data.content);
      setCurrentPage(trans.data.number);
      setTotalPages(trans.data.totalPages);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    getData(pageNumber);
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const handleCreateCompany = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    getData();
  };


  const handleSearchInvoiceNumber = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setInvoiceNumberFilter(searchTerm);
    setSelectedDate(null); // Clear date filter when searching by invoice number
    filterInvoices(searchTerm, null, statusFilter);
  };
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setInvoiceNumberFilter(""); // Clear invoice number filter when searching by date
    filterInvoices("", date, statusFilter);
  };
  
  const handleStatusFilterChange = (e) => {
    const status = e.target.value;
    setStatusFilter(status);
    filterInvoices(invoiceNumberFilter, selectedDate, status);
  };
  
  const filterInvoices = (invoiceNumber, date, status) => {
    let filtered = invoices;
  
    if (invoiceNumber) {
      filtered = filtered.filter((invoice) =>
        invoice.invoicenumber?.toLowerCase().includes(invoiceNumber)
      );
    }
  
    if (date) {
      const selectedMonth = date.getMonth();
      const selectedYear = date.getFullYear();
      filtered = filtered.filter((invoice) => {
        const invoiceDate = new Date(invoice.date);
        return (
          invoiceDate.getMonth() === selectedMonth &&
          invoiceDate.getFullYear() === selectedYear
        );
      });
    }
  
    if (status && status !== "all") {
      filtered = filtered.filter((invoice) => invoice.status === status);
    }
  
    setFilteredInvoices(filtered);
  };
  
  const handleBack = () => {
      navigate(-1);
    };
    

  const handleViewPdf = async (id) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/invoices/download/id=${id}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      console.log("filedata;", response);
      const contentType = response.headers["content-type"];
      let extension = "";
  
      // Determine the file extension based on content type
      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }
  
      // Create a blob URL and download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  }

  const [loading, setLoading] = useState(null);

  function rsubmit(e, id, status) {
    e.preventDefault();
    setLoading(id);
  
    const token = JSON.parse(localStorage.getItem("data")).token;
  
    const entitiesToUpdate = ({
      id: id,
      status: status,
    });
  
    axios
      .post(
        "/api/companyinvoices/update",
        entitiesToUpdate,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(null);
        getData();
      })
      .catch((error) => {
       handleError(error);
      });
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    let startPage = Math.max(0, currentPage - 2);
    let endPage = Math.min(totalPages - 1, currentPage + 2);

    if (currentPage <= 2) {
      endPage = Math.min(totalPages - 1, maxPageNumbersToShow - 1);
    }

    if (currentPage >= totalPages - 3) {
      startPage = Math.max(0, totalPages - maxPageNumbersToShow);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };


  return (
    <div>
        <div className="col-12 mx-xl-auto">
          <br />
          <div className="card card-raised">
            <div className="card-body">
              <div className="card-title form-group d-flex align-items-center justify-content-between">
                <span>Lab Incoming Invoices :</span>
                {/* <div>
                  <button className="btn btn-primary" onClick={handleCreateCompany}>
                    ADD
                  </button>
                </div> */}
              </div>

              <br />
              <div className="row">
                <div className="col-md-3 mb-3">
                  <label htmlFor="invoiceNumberFilter" className="form-label">
                    Search by Invoice Number:
                  </label>
                  <input
                    type="search"
                    className="form-control"
                    id="invoiceNumberFilter"
                    placeholder="Search by Invoice Number"
                    value={invoiceNumberFilter}
                    onChange={handleSearchInvoiceNumber}
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="monthYearFilter" className="form-label">
                    Filter by Month and Year:
                  </label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    className="form-select"
                    placeholderText="Select Month and Year"
                  />
                </div>

                <div className="col-md-3 mb-3">
                  <label htmlFor="statusFilter" className="form-label">
                    Filter by Status:
                  </label>
                  <select
                    id="statusFilter"
                    className="form-select"
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                  >
                    <option value="all">ALL</option>
                    <option value="RECIEVED">RECIEVED</option>
                    <option value="PAID">PAID</option>
                    <option value="REJECTED">REJECTED</option>
                  </select>
                </div>
  
              </div>
  
                <br />
                <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                      <th>Date</th>
                      <th>Period</th>
                      <th>Invoice Number</th>
                      <th>Gross Amount</th>
                      <th>Tax</th>
                      <th>Net Pay</th>
                      <th>File</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInvoices.length === 0 ? (
                      <tr>
                        <td colSpan="10" className="text-center">
                          No Data Available
                        </td>
                      </tr>
                    ) : (
                      filteredInvoices.map((invoice) => (
                        <tr key={invoice.id}>
                          <td>{invoice.date} </td>
                          <td>{invoice.startdate} to {invoice.enddate}</td>
                          <td>{invoice.invoicenumber}</td>
                          <td>{invoice.grossamount}</td>
                          <td>{invoice.tax}</td>
                          <td>{invoice.netpay}</td>
                          <td>
                      {invoice.file ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => handleViewPdf(invoice.id)}
                          >
                            View
                          </button>
                        ) : (
                          "No File Added"
                        )}
                        </td>
                        {invoice.status === "SUBMITTED" ? (
                              <td id="icon" value={"RECIEVED"}>
                                &nbsp;
                                {"RECIEVED"} &nbsp; &nbsp;&ensp;
                                {loading && (
                                  <div
                                    className="loader"
                                    style={{
                                      position: "relative",
                                      left: "75px",
                                      top: "10px",
                                    }}
                                  ></div>
                                )}{" "}
                                <BsCheckCircle
                                  className="icon"
                                  id="invoice.status"
                                  style={{ backgroundColor: "green" }}
                                  value="PAID"
                                  onClick={(e) =>
                                    rsubmit(e, invoice.id, "PAID")
                                  }
                                />
                                &ensp;&ensp;
                                <BsXCircle
                                  className="icon"
                                  value="REJECTED"
                                  style={{ backgroundColor: "red" }}
                                  id="invoice.status"
                                  onClick={(e) =>
                                    rsubmit(e, invoice.id, "REJECTED")
                                  }
                                />
                              </td>
                            ) : (
                              <td id="icon" value={invoice.status}>
                                {" "}
                                {invoice.status}
                              </td>
                            )}
                          

                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
  
       
              <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage === 0} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0} />
                {renderPageNumbers()}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages - 1} />
                <Pagination.Last onClick={() => handlePageChange(totalPages - 1)} disabled={currentPage === totalPages - 1} />
              </Pagination>
            </div>

              {/* <div>
                {isModalOpen && <AddInvoicesByAdmin isOpen={isModalOpen} onRequestClose={handleCloseModal} />}
              </div> */}
            </div>
          </div>
        </div>
      {loader && <div className="loading"></div>}
    </div>
  );
}

export default LabIncomingInvoices;
