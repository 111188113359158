import React, { useEffect, useState } from "react";
import Dashboard from "./dashboard";
import axios from "../api/baseurl";
import "./Spinner.css";
import "./Loader.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable"; 
import tmplLogo from "../images/tmplLogo.png";

function ViewAppointmentReportsByAdmin() {
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(false); 
  const [fromDate, setFromDate] = useState(""); 
  const [toDate, setToDate] = useState(""); 
  const [status, setStatus] = useState(""); 
  const [appNotTakenData, setAppNotTakenData] = useState([]);
  const [selectedOrgcode, setSelectedOrgcode] = useState(null);
  const [companyDetails, setCompanyDetails] = useState([]);

  const navigate = useNavigate();

  // Function to fetch data based on selected fromDate, toDate, and status
const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    // Prepare request options with Authorization header
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
  
    try {
      setLoader(true); // Show loader while fetching data
  
      let response;
  
      if (status && selectedOrgcode) {
        // If status is selected, call the API specific for status
        const requestBody = {
          companyCode: selectedOrgcode,
          fromDate: fromDate,
          toDate: toDate,
          appointmentStatus: status
        };
  
        response = await axios.post('/api/getAppointmentsByStatus', requestBody, options);
      } 
      else if (status && !selectedOrgcode) {
        // If status is selected, call the API specific for status
        const requestBody = {
          fromDate: fromDate,
          toDate: toDate,
          appointmentStatus: status
        };
  
        response = await axios.post('/api/getAllAppointmentsByDateRangeAndStatusByRole', requestBody, options);
      }
      else if (!status && selectedOrgcode) {
        const url = `/api/getAppointments/companycode=${selectedOrgcode}/fromdate=${fromDate}/todate=${toDate}`;
        response = await axios.get(url, options);
      }
    else if (!status && !selectedOrgcode) {
      const url = `/api/getAppointmentsByRole/fromdate=${fromDate}/todate=${toDate}`;
      response = await axios.get(url, options);
    }
  
      // Set the appointment details from the response
      setAppointmentDetails(response.data.empAppointments);
      setAppNotTakenData([]);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false); // Hide loader after fetching data
    }
  };

  const getAppNotTakenEmpData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    
    // Prepare request options with Authorization header
    const options = {
      headers: { Authorization: `Bearer ${token}` },
    };
  
    try {
      setLoader(true); 
      let response;
  
      if (selectedOrgcode) {
        response = await axios.get(`/api/employeedetails/getAppointmentsNotTakenEmployees/companycode=${selectedOrgcode}`, options);
      } 
      else {
        response = await axios.get('/api/employeedetails/getAllAppointmentsNotTakenEmployees', options);
      }
      setAppNotTakenData(response.data);
    } catch (error) {
      handleError(error);
    } finally {
      setLoader(false); // Hide loader after fetching data
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("data")).token;
        const companyRes = await axios.get("/api/companydetails/getCompanies", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCompanyDetails(companyRes.data);
      } catch (error) {
        handleError(error);
      }
    };
    fetchData();
  }, []);
  
  // Error handling function
  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    } else {
      toast.error("An error occurred.");
    }
  }

  // Function to handle "Generate" button click
  const handleGenerateReport = () => {
    // Only fetch data if both fromDate and toDate are selected
    if (fromDate && toDate) {
      getData();
    } else {
      toast.error("Please select both From Date and To Date.");
    }
  };

  const handleAppNotTakenReport = () => {
      getAppNotTakenEmpData();
  };

  const handleDownloadReport = () => {
    if (appNotTakenData.length > 0) {
      const doc = new jsPDF('l', 'mm', 'a4');
  
      const logoWidth = 40;
      const logoHeight = 10;
      const titleFontSize = 16;
      const titleMargin = 10;
  
      // Add Logo to the PDF
      doc.addImage(tmplLogo, 'PNG', 10, 10, logoWidth, logoHeight);
  
      // Title
      doc.setFontSize(titleFontSize);
      const pageWidth = doc.internal.pageSize.width;
      const titleText = "Appointment Not Taken Employees Report";
      const titleWidth = doc.getStringUnitWidth(titleText) * titleFontSize / doc.internal.scaleFactor;
      const titleX = (pageWidth - logoWidth - titleMargin - titleWidth) / 2 + logoWidth + titleMargin;
  
      doc.text(titleText, titleX, 20);
      doc.setFontSize(12);
  
      if (selectedOrgcode) {
        doc.text(`Company Code: ${selectedOrgcode}`, 14, 30);
      }
  
      const tableColumn = [
        "Sl No.",
        "Name",
        "Employee ID",
        "Email",
        "Phone Number",
        "Company Code",
      ];
      const tableRows = [];
  
      appNotTakenData.forEach((appointment, index) => {
        const appointmentData = [
          index + 1,
          `${appointment.name} ${appointment.lastname}`,
          appointment.empid,
          appointment.email,
          appointment.phoneno,
          appointment.companycode
        ];
        tableRows.push(appointmentData);
      });
  
      let startY = selectedOrgcode ? 40 : 30;
      
    // First pass: Generate all pages without the page numbers
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: startY,
      margin: { top: 50, bottom: 30 },
      pageBreak: 'auto',
      showHead: 'firstPage',
      theme: 'striped',
      didDrawPage: (data) => {
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;

        // Footer text
        const footerText = "This is a system-generated report and does not need a signature.";
        doc.setFontSize(10);
        doc.setTextColor(128, 128, 128);
        doc.text(footerText, 14, pageHeight - 20);
      }
    });

    const pageCount = doc.internal.getNumberOfPages();

    // Second pass: Go back and add page numbers to all pages
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 20, pageHeight - 10, { align: 'right' });
    }

    // Save the document
    doc.save(`Appointment_Report.pdf`);
    } else {
      // Handle empty case for appointmentDetails similarly
      if (appointmentDetails.length === 0) {
        toast.error("No data available to generate PDF.");
        return;
      }
  
      const doc = new jsPDF('l', 'mm', 'a4');
      const logoWidth = 40;
      const logoHeight = 10;
      const titleFontSize = 16;
      const titleMargin = 10;
  
      // Add Logo to the PDF
      doc.addImage(tmplLogo, 'PNG', 10, 10, logoWidth, logoHeight);
  
      // Title
      doc.setFontSize(titleFontSize);
      const pageWidth = doc.internal.pageSize.width;
      const titleText = "Employees Appointment Report";
      const titleWidth = doc.getStringUnitWidth(titleText) * titleFontSize / doc.internal.scaleFactor;
      const titleX = (pageWidth - logoWidth - titleMargin - titleWidth) / 2 + logoWidth + titleMargin;
  
      doc.text(titleText, titleX, 20);
  
      // Date texts
      doc.setFontSize(12);
      doc.text(`From Date: ${fromDate}`, 14, 30);
      doc.text(`To Date: ${toDate}`, 14, 40);
  
      if (status && !selectedOrgcode) {
        doc.text(`Appointment Status: ${status}`, 14, 50);
      } else if (status && selectedOrgcode) {
        doc.text(`Company Code: ${selectedOrgcode}`, 14, 50);
        doc.text(`Appointment Status: ${status}`, 14, 60);
      } else if (!status && selectedOrgcode) {
        doc.text(`Company Code: ${selectedOrgcode}`, 14, 50);
      }
  
      const tableColumn = [
        "Sl No.",
        "Name",
        "Employee ID",
        "Email",
        "Phone Number",
        "Company Name",
        "Lab Name",
        "Lab Code",
        "Appointment Status",
      ];
      const tableRows = [];
  
      appointmentDetails.forEach((appointment, index) => {
        const appointmentData = [
          index + 1,
          `${appointment.emplo?.name} ${appointment.emplo?.lastname}`,
          appointment.emplo?.empid,
          appointment.emplo?.email,
          appointment.emplo?.phoneno,
          appointment.company?.companyname,
          appointment.labs?.labname,
          appointment.labs?.labcode,
          appointment.appStatus?.appointmentStatus,
        ];
        tableRows.push(appointmentData);
      });
  
      let startY;
      if (status && !selectedOrgcode) {
        startY = 60;
      } else if (status && selectedOrgcode) {
        startY = 70;
      } else if (!status && selectedOrgcode) {
        startY = 60;
      } else {
        startY = 50;
      }
  
      // First pass: Generate all pages without the page numbers
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: startY,
      margin: { top: 50, bottom: 30 },
      pageBreak: 'auto',
      showHead: 'firstPage',
      theme: 'striped',
      didDrawPage: (data) => {
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;

        // Footer text
        const footerText = "This is a system-generated report and does not need a signature.";
        doc.setFontSize(10);
        doc.setTextColor(128, 128, 128);
        doc.text(footerText, 14, pageHeight - 20);
      }
    });

    const pageCount = doc.internal.getNumberOfPages();

    // Second pass: Go back and add page numbers to all pages
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);
      doc.text(`Page ${i} of ${pageCount}`, pageWidth - 20, pageHeight - 10, { align: 'right' });
    }

    // Save the document
    doc.save(`Appointment_Report_${fromDate}_to_${toDate}.pdf`);
    }
  };
  
  
  return (
    <div>
      <Dashboard />
      {loader && <div className="loading"></div>}
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              Appointments Reports
            </div>
            <br />
            <div className="row">
            <div className="col-md-3 mb-3">
    <label htmlFor="companycode">
      Select Company
    </label>
    <select
      className="form-select"
      onChange={(e) => setSelectedOrgcode(e.target.value)} // Set selected org code here
    >
      <option value="">All</option>
      {companyDetails.map((comp) => (
        <option key={comp.id} value={comp.companycode}>
          {comp.companyname}
        </option>
      ))}
    </select>
  </div>


              <div className="col-md-3">
                <label>From Date:</label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>To Date:</label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label>Appointment Status :</label>
                <select
                  className="form-select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="">ALL</option>
                  <option value="APPOINTMENT CONFIRMATION PENDING WITH LAB">
                    APPOINTMENT CONFIRMATION PENDING 
                  </option>
                  <option value="APPOINTMENT CONFIRMED">APPOINTMENT CONFIRMED</option>
                  <option value="APPOINTMENT COMPLETED">APPOINTMENT COMPLETED</option>
                  <option value="APPOINTMENT CANCELLED">APPOINTMENT CANCELLED</option>
                </select>
              </div>
            </div>
  
            <br />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <button className="btn btn-secondary" onClick={handleAppNotTakenReport}>
                Appointment Not Taken 
                </button>
              <div>
                <button className="btn btn-primary me-4" onClick={handleGenerateReport}>
                  Generate Report
                </button>
                <button className="btn btn-primary" onClick={handleDownloadReport}>
                  Download Report
                </button>
              </div>
            </div>
  
            <br />
            {appNotTakenData.length === 0 && appointmentDetails.length>0 &&(
                    <>
                    <h5>Appointment Taken Employees :</h5>
                    <br/>
            <table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>Name</th>
                  <th>Employee Id</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Company Name</th>
                  <th>Lab Name</th>
                  <th>Lab Code</th>
                  <th>Appointment Status</th>
                </tr>
              </thead>
              <tbody>
                {/* Render Regular Appointment Details */}
                {appointmentDetails.length === 0 && appNotTakenData.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  <>
                    {appointmentDetails.map((appointment) => (
                      <tr key={appointment.id}>
                        <td>
                          {appointment.emplo?.name} {appointment.emplo?.lastname}
                        </td>
                        <td>{appointment.emplo?.empid}</td>
                        <td>{appointment.emplo?.email}</td>
                        <td>{appointment.emplo?.phoneno}</td>
                        <td>{appointment.company?.companyname}</td>
                        <td>{appointment.labs?.labname}</td>
                        <td>{appointment.labs?.labcode}</td>
                        <td>{appointment.appStatus?.appointmentStatus}</td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
            </>
            )}
{appNotTakenData.length > 0 && (
    <>
    <h5>Appointment Not Taken Employees :</h5>
    <br/>
<table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>Name</th>
                  <th>Employee Id</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Company Code</th>
                </tr>
              </thead>
              <tbody>
                {/* Render Regular Appointment Details */}
                {appNotTakenData.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
       
                    appNotTakenData.map((employee) => (
                      <tr key={employee.id}>
                        <td>
                          {employee.name} {employee.lastname}
                        </td>
                        <td>{employee.empid}</td>
                        <td>{employee.email}</td>
                        <td>{employee.phoneno}</td>
                        <td>{employee.companycode}</td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
            </>
            )}
          </div>
        </div>
      </div>
      {loader && <div className="loading"></div>}
    </div>
  );
  
}

export default ViewAppointmentReportsByAdmin;
