import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';  
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AdminDashboard from "./admindashboard";
import LabProfile from "./labProfile";
import LabAdminLink from "./labAdminLink";
import ViewAppointmentsByLabCorporate from "./viewAppointmentsByLabCorporate";
import LabCoordinators from "./labCoordinators";
import ViewLabInvoices from "./viewLabInvoices";
import LabInvoices from "./labInvoicesTab";

function LabProfileTab() {
  const { orgcode } = useParams();
  const userRole = JSON.parse(localStorage.getItem("data")).role;
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('labProfile');

  useEffect(() => {
    // Any additional side effects (if needed) when tab changes can be handled here
  }, [activeTab]); // This effect will run when the activeTab changes

  return (
    <div>
      <AdminDashboard />
  
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <Tabs
              id="lab-profile-tab"
              activeKey={activeTab}
              onSelect={(tab) => setActiveTab(tab)} // Update the active tab
              className="mb-3"
            >
              <Tab eventKey="labProfile" title="Lab Profile">
                {activeTab === 'labProfile' && <LabProfile selectedOrgcode={orgcode} />}
              </Tab>

              <Tab eventKey="labCoordinators" title="Lab Co-ordinators">
                {activeTab === 'labCoordinators' && <LabCoordinators labcode={orgcode} />}
              </Tab>

              <Tab eventKey="appointments" title="Appointments">
                {activeTab === 'appointments' && <ViewAppointmentsByLabCorporate labcode={orgcode} />}
              </Tab>

              <Tab eventKey="invoices" title="Invoices">
                {activeTab === 'invoices' && <LabInvoices labcode={orgcode} />}
              </Tab>

              {userRole === 'ROLE_SUPER' && (
                <Tab eventKey="adminLink" title="Admin Link">
                  {activeTab === 'adminLink' && <LabAdminLink selectedOrgcode={orgcode} />}
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LabProfileTab;
