import React, { useEffect, useState } from "react";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiSolidCheckCircle, BiSolidXCircle } from "react-icons/bi";
import UploadReport from "./uploadReport";
import TestForPackage from "./testsByPackagePopup";
import { FiInfo } from "react-icons/fi";
import AdminDashboard from "./admindashboard";

function ReportsApproval() {
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filter, setFilter] = useState("REQUESTED"); // Default filter set to "REQUESTED"
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;

    setLoader(true);
    try {
      const response = await axios.get(`/api/employeeappointment/getAllUploadedReports`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAppointmentDetails(response.data);
      setLoader(false);
    } catch (error) {
      handleError(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      } else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }

  const [pdfUrl, setPdfUrl] = useState(null);
  const handleViewPdf = async (appointmentId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    await axios({
      url: `/api/employeeappointment/download/id=${appointmentId}`,
      method: "GET",
      responseType: "blob",
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      const contentType = response.headers["content-type"];
      let extension = "";

      if (contentType.includes("application/pdf")) {
        extension = "pdf";
      } else if (contentType.includes("image/jpeg")) {
        extension = "jpg";
      } else if (contentType.includes("image/png")) {
        extension = "png";
      } else {
        console.error("Unsupported file type:", contentType);
        return;
      }

      const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file.${extension}`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  const [loading, setLoading] = useState(null);
  function rsubmit(e, id, status) {
    e.preventDefault();
    setLoading(id);

    const token = JSON.parse(localStorage.getItem("data")).token;

    const entitiesToUpdate = ({
      id: id,
      reportstatus: status,
    });

    axios
      .post(
        "/api/employeeappointment/approve",
        entitiesToUpdate,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setLoading(null);
        getData();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);

  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packageid=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  const closeDenominationModal = () => {
    setIsTestsModalOpen(false);
  };

  // Filter appointment details based on the selected filter
  const filteredAppointmentDetails = filter === "ALL"
    ? appointmentDetails
    : appointmentDetails.filter(appointment => appointment.reportstatus === filter);

  return (
    <div>
      <AdminDashboard />
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
          <div className="card-title form-group d-flex align-items-center justify-content-between">
          Reports Approval</div>

            <div className="d-flex col-3 mb-3 mt-2">
              <label htmlFor="statusFilter" className="form-status">
                Filter by Status:
              </label>
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="form-select mb-3"
              >
                <option value="ALL">ALL</option>
                <option value="REQUESTED">REQUESTED</option>
                <option value="APPROVED">APPROVED</option>
                <option value="REJECTED">REJECTED</option>
              </select>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr className="table-header">
                  <th>Lab Name</th>
                  <th>Company Name</th>
                  <th>Employee Name</th>
                  <th>Date</th>
                  <th>Contact Number</th>
                  <th>Package Name</th>
                  <th>Appointment Status</th>
                  <th>Report</th>
                  <th>Report Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredAppointmentDetails.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  filteredAppointmentDetails.map((appointment) => (
                    <tr key={appointment.id}>
                      <td>{appointment.labs?.labname}</td>
                      <td>{appointment.company?.companyname}</td>
                      <td>{appointment.emplo?.name} {appointment.emplo?.lastname}</td>
                      <td>{appointment.date}</td>
                      <td>{appointment.contact}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{appointment.pack?.packagename}</span>
                        <FiInfo
                          onClick={() => openTestsModal(appointment.packageid)}
                          style={{
                            cursor: 'pointer'
                          }}
                        />
                      </td>
                      <td>{appointment.appointmentstatus}</td>
                      <td>
                        {appointment.file ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => handleViewPdf(appointment.id)}
                          >
                            View
                          </button>
                        ) : (
                          "No Report Added"
                        )}
                      </td>
                      <td>
                        {appointment.file ? (
                          <>
                            {appointment.reportstatus === "REQUESTED" ? (
                              <td id="icon" value={appointment.reportstatus}>
                                <div>
                                {appointment.reportstatus} 
                                </div>
                                <BiSolidCheckCircle
                                  className="icon"
                                  id="appointment.reportstatus"
                                  style={{ backgroundColor: "green" }}
                                  value="APPROVED"
                                  onClick={(e) =>
                                    rsubmit(e, appointment.id, "APPROVED")
                                  }
                                />
                                &ensp;
                                <BiSolidXCircle
                                  className="icon"
                                  value="REJECTED"
                                  style={{ backgroundColor: "red" }}
                                  id="appointment.reportstatus"
                                  onClick={(e) =>
                                    rsubmit(e, appointment.id, "REJECTED")
                                  }
                                />
                              </td>
                            ) : (
                              <td id="icon" value={appointment.reportstatus}>
                                {appointment.reportstatus}
                              </td>
                            )}
                          </>
                        ) : (
                          "No Report Added"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        {isTestsModalOpen && (
          <TestForPackage
            isOpen={isTestsModalOpen}
            onRequestClose={closeDenominationModal}
            testDetails={tests}
          />
        )}
        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default ReportsApproval;
